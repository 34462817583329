import React from "react";
import { motion } from "framer-motion";

export function Rama() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="601"
      height="647"
      fill="none"
      viewBox="0 0 501 447"
      className="ramaIntro"
    >
      <g id="main_branch">
        <motion.g
          style={{
            originX: "0px",
            originY: "413px",
          }}
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            // rotate:[0,20,0]
          }}
          transition={{
            // loop:Infinity,
            duration: 4
          }}
        >
          <g id="florCojollo1">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 2, 3, 4, 0, 1, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <path
                id="tallo"
                fill="#91705B"
                d="M177.859 210.673c-.897-27.173 9.103-44.173 19.103-63.673-1 14.5-12.096 21.308-14.5 59-1.516.252-4.082 4.894-4.603 4.673z"
              ></path>
              <g id="petalos">
                <path
                  id="Ellipse 1"
                  fill="url(#paint0_radial_18_101)"
                  d="M215.298 96.563c-4.938-1.895-15.461 9.649-19.769 20.872-4.307 11.223-.415 19.063 4.523 20.959 4.938 1.895 9.743 6.277 17.691-4.686 5.819-10.506 2.493-35.25-2.445-37.145z"
                ></path>
                <path
                  id="Ellipse 2"
                  fill="url(#paint1_linear_18_101)"
                  d="M216.359 97.187c7.198 22.881.693 33.669-9.43 48.721-.899 1.08-1.672 2.172-2.245 3.332.764-1.137 1.514-2.245 2.245-3.332 6.965-8.368 21.507-16.005 9.43-48.721z"
                ></path>
                <g id="Ellipse 3" filter="url(#filter0_d_18_101)">
                  <path
                    fill="url(#paint2_linear_18_101)"
                    d="M215.35 95.516c2.702 25.519-2.893 36.888-15.552 50.495 10.081-6.214 25.428-12.446 15.552-50.495z"
                    shapeRendering="crispEdges"
                  ></path>
                </g>
                <g id="Vector 3" filter="url(#filter1_f_18_101)">
                  <path
                    fill="url(#paint3_linear_18_101)"
                    d="M194.175 131.28c-2.118-28.955 13.335-35.844 21.326-35.669-9.686 12.588-14.142 32.155-15.16 40.365-1.172 10.498-4.048 24.258-6.166-4.696z"
                  ></path>
                </g>
                <path
                  id="Vector 1"
                  fill="url(#paint4_linear_18_101)"
                  d="M191.67 158.016c10.679-6.22 31.433-22.371 29.019-37.216-.202.952-1.511 8.038-3.509 9.006-.531-.312-.048-5.384-1.531-5.899-1.483-.515-8.926 5.465-7.614 10.521-.421.11-3.277-.498-3.37.875-.092 1.348-6.936-13.309-5.511-15.568.15-.168.26-.288.318-.349.058-.063.062-.066 0 0l-.228.233a.702.702 0 00-.09.116c-1.284 1.438-5.505 6.467-4.995 9.75-1.121-.186-3.121-.693-3.856-1.445-.163-.588-.266-.84-.28-.63-.016.225.09.436.28.63.901 3.249 3.644 16.755 3.486 19.081-.149 2.198-1.475 8.179-2.119 10.895z"
                ></path>
                <g id="Vector 2" filter="url(#filter2_f_18_101)">
                  <path
                    fill="url(#paint5_linear_18_101)"
                    d="M215.009 123.174c-2.391 8.116-8.631 24.278-14.462 23.992-1.652-1.318-2.543-17.68-1.294-24.727.14-.349.192-.559.117-.603-.041.192-.08.393-.117.603-.858 2.133-5.044 9.457-4.171 15.801.078.3-2.778-4.561-4.207-7.772-.35-1.551-.622-2.565-.743-2.637-.18.306.165 1.336.743 2.637 1.184 5.247 3.255 16.628 3.352 18.339.1 1.772-1.466 5.327-2.261 6.883l1.171 1.045c5.248-3.461 15.932-10.701 16.681-11.975.935-1.592 6.503-16.53 5.191-21.586z"
                  ></path>
                </g>
              </g>
            </motion.g>
          </g>
          <g id="Flor_rama">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 1, 2, 3, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <path
                id="Vector 5"
                fill="#91705B"
                d="M271.188 150.092c-13.825-19.526-7.151-60.157-10.035-55.028-3.409 47.67 2.601 48.71 6.444 53.496 1.034.517 3.2 1.547 3.591 1.532z"
              ></path>
              <g id="Flor">
                <path
                  id="petalo-fondo"
                  fill="url(#paint6_linear_18_101)"
                  d="M260.768 95.543c-4.703-3.002-14.448-9.564-15.805-11.804-1.696-2.8-14.934-16.138-10.948-22.744 2.876-3.217 6.557-10.045 13.154 1.384.037-.474 3.197-10.805 11.381-9.51 8.467 1.342 13.26 7.604 15.444 10.386 2.198-1.042 14.447-.326 20.094 5.425-.671.265 12.263 13.376 1.887 17.126.304.227 4.559 3.405 4.919 4.824.361 1.42-6.432 8.612-11.309 8.803-3.902.152-17.223-1.875-23.396-2.908l-5.421-.982z"
                ></path>
                <path
                  id="Vector 9"
                  fill="url(#paint7_radial_18_101)"
                  stroke="url(#paint8_linear_18_101)"
                  d="M296.83 85.295c-3.642 8.933-25.517 10.075-35.999 9.529 2.243-7.653 7.599-23.994 11.077-28.144 3.478-4.149 15.628-.85 21.269 1.32 2.735 2.042 7.295 8.362 3.653 17.295z"
                ></path>
                <path
                  id="Vector 10"
                  fill="url(#paint9_linear_18_101)"
                  stroke="url(#paint10_linear_18_101)"
                  d="M300.951 91.822c-13.227 16.188-31.287 9.982-38.664 4.856l25.029-10.056c5.405-.052 15.699.915 13.635 5.2z"
                ></path>
                <path
                  id="Vector 8"
                  fill="url(#paint11_radial_18_101)"
                  stroke="url(#paint12_linear_18_101)"
                  d="M274.106 65.639c.673 2.65-8.207 21.342-12.731 30.358l-9.866-18.486c-.745-1.962-2.655-7.35-4.34-13.208-2.106-7.323 8.454-11.319 12.052-11.698 3.598-.38 9.365 5.845 9.726 7.264.36 1.419 4.318 2.459 5.159 5.77z"
                ></path>
                <path
                  id="petalo"
                  fill="url(#paint13_radial_18_101)"
                  d="M272.204 85.302c-7.215-.483-10.508 6.462-11.253 9.995 1.055.277 3.47 1.059 4.686 1.966 1.216.908 17.249.361 24.626-.007-.961-3.784-10.844-11.472-18.059-11.954z"
                ></path>
                <path
                  id="petalo_2"
                  fill="url(#paint14_radial_18_101)"
                  stroke="url(#paint15_linear_18_101)"
                  d="M231.152 76.873c1.758 12.353 20.476 17.594 29.615 18.67 0 0-8.078-7.568-11.726-10.292-3.647-2.724-16.419-10.346-17.889-8.378z"
                ></path>
                <path
                  id="Vector 6"
                  fill="url(#paint16_radial_18_101)"
                  stroke="#F3C2B4"
                  d="M234.015 60.995c-.882 14.367 17.713 28.69 27.12 34.056-11.302-9.592-12.015-30.824-13.951-34.57-1.937-3.747-10.214-7.246-13.169.514z"
                ></path>
                <path
                  id="Vector 7"
                  fill="url(#paint17_radial_18_101)"
                  stroke="url(#paint18_linear_18_101)"
                  d="M265.653 76.958c2.651 8.114-1.624 15.91-4.094 18.793-17.324-12.94-15.556-12.77-17.373-16.043-1.559-2.81.441-9.254 1.45-12.717 2.393 1.02 7.558 3.192 9.068 3.706 1.887.643 7.252.433 10.949 6.261z"
                ></path>
                <path
                  id="Vector 5_2"
                  fill="url(#paint19_radial_18_101)"
                  stroke="url(#paint20_linear_18_101)"
                  d="M255.566 84.757c1.311 1.287 4.462 7.55 5.873 10.52-17.939-11.481-16.165-13.223-18.335-19.827.121-16.01 6.97-5.527 5.62-3.086-1.35 2.442 5.202 10.785 6.842 12.393z"
                ></path>
                <path
                  id="petalo_3"
                  fill="url(#paint21_radial_18_101)"
                  stroke="url(#paint22_linear_18_101)"
                  d="M290.504 98.203c-1.58 5.918-24.031 5.052-30.404-3.857 0 0 1.302 1.713 5.481 1.725 4.178.012 22.527-9.241 24.923 2.132z"
                ></path>
              </g>
            </motion.g>
          </g>
          <g id="Flor_rama_2">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 1, 2, 3, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <g id="Flor_2">
                <path
                  id="petalo-fondo_2"
                  fill="url(#paint23_linear_18_101)"
                  d="M136.759 164.811c-11.496 2.173-35.811 6.151-41.103 4.679-6.615-1.84-46.002-3.191-49.405-19.01-.229-9.046-4.418-24.767 22.082-16.787-.624-.778-10.643-21.1 3.72-30.879 14.861-10.12 31.18-7.44 38.513-6.329 1.861-4.75 21.601-21.258 38.491-20.588-.644 1.37 37.953 2.81 27.491 23.45.791-.09 11.857-1.35 14.447.3 2.59 1.649 2.549 22.398-4.627 29.697-5.74 5.84-29.002 21.885-39.916 29.177l-9.693 6.29z"
                ></path>
                <path
                  id="Vector 9_2"
                  fill="url(#paint24_radial_18_101)"
                  stroke="url(#paint25_linear_18_101)"
                  d="M177.12 97.336c7.273 18.879-24.501 52.056-41.297 66.285-7.569-14.913-22.87-47.571-23.52-58.907-.65-11.335 22.651-23.754 34.383-28.547 7.114-.81 23.16 2.29 30.434 21.17z"
                ></path>
                <path
                  id="Vector 10_2"
                  fill="url(#paint26_linear_18_101)"
                  stroke="url(#paint27_linear_18_101)"
                  d="M192.794 101.386c3.057 43.733-33.448 60.205-52.083 62.975l23.783-51.326c8.182-7.846 25.296-21.16 28.3-11.649z"
                ></path>
                <path
                  id="Vector 8_2"
                  fill="url(#paint28_radial_18_101)"
                  stroke="url(#paint29_linear_18_101)"
                  d="M114.164 99.965c4.835 3.08 18.132 44.394 24.176 64.666l-41.635-14.06c-3.956-1.927-14.615-7.412-25.607-13.939-13.74-8.16-3.351-29.438 1.599-35.188 4.95-5.75 22.704-4.53 25.294-2.88 2.59 1.651 10.13-2.449 16.173 1.4z"
                ></path>
                <path
                  id="petalo_4"
                  fill="url(#paint30_radial_18_101)"
                  d="M139.513 132.733c-11.715 9.631-6.766 24.972-2.827 31.438 2.011-1.093 6.823-3.369 9.984-3.729 3.162-.36 26.867-24.235 37.607-35.397-6.906-4.4-33.049-1.943-44.764 7.688z"
                ></path>
                <path
                  id="petalo_5"
                  fill="url(#paint31_radial_18_101)"
                  stroke="url(#paint32_linear_18_101)"
                  d="M64.693 178.849c20.437 16.343 56.559-2.549 72.066-14.038 0 0-23.214.05-32.7 1.129-9.485 1.08-39.948 7.789-39.366 12.909z"
                ></path>
                <path
                  id="Vector 6_2"
                  fill="url(#paint33_radial_18_101)"
                  stroke="#F3C2B4"
                  d="M46.251 150.48c19.297 23.215 68.282 18.373 90.362 13.051-31.046 1.589-62.645-29.819-70.987-32.759-8.341-2.94-26.012 3.609-19.375 19.708z"
                ></path>
                <path
                  id="Vector 7_2"
                  fill="url(#paint34_radial_18_101)"
                  stroke="url(#paint35_linear_18_101)"
                  d="M117.516 129.403c15.709 8.583 20.38 26.635 20.751 34.588-45.055 5.128-42.112 2.849-49.59.459-6.418-2.052-12.624-14.77-16.06-21.509 5.124-1.88 16.133-5.985 19.178-7.369 3.807-1.73 11.7-9.759 25.721-6.169z"
                ></path>
                <path
                  id="Vector 5_3"
                  fill="url(#paint36_radial_18_101)"
                  stroke="url(#paint37_linear_18_101)"
                  d="M113.315 155.811c3.853.08 17.664 5.12 24.089 7.63-43.901 8.238-43.693 3.029-56.497-3.941-22.82-24.629 2.703-18.459 4.149-12.789 1.446 5.67 23.444 9 28.259 9.1z"
                ></path>
                <path
                  id="petalo_6"
                  fill="url(#paint38_radial_18_101)"
                  stroke="url(#paint39_linear_18_101)"
                  d="M186.004 126.144c6.092 11.311-29.447 42.248-51.983 37.797 0 0 4.451.747 10.85-5.239 6.4-5.987 21.132-46.487 41.133-32.558z"
                ></path>
              </g>
            </motion.g>
          </g>
          <g id="florCojollo2">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 1, 2, 3, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <path
                id="tallo_2"
                fill="#91705B"
                d="M265.096 122.682c19.498-15.38 49.128-12.471 58.321-11.673-32.013-8.259-53.998 5.777-57.549 8.81-.288.824-.845 2.55-.772 2.863z"
              ></path>
              <g id="petalos_2">
                <path
                  id="Ellipse 1_2"
                  fill="url(#paint40_radial_18_101)"
                  d="M352.007 114.457c-.358 3.432-10.182 6.137-17.981 5.323-7.799-.813-11.206-5.394-10.848-8.826.357-3.431-.77-7.52 7.954-8.9 7.829-.278 21.233 8.971 20.875 12.403z"
                ></path>
                <path
                  id="Ellipse 2_2"
                  fill="url(#paint41_linear_18_101)"
                  d="M351.958 113.655c-11.176-10.949-19.372-10.357-31.117-8.921-.894.204-1.757.33-2.601.32.887-.108 1.753-.216 2.601-.32 6.925-1.576 15.665-7.773 31.117 8.921z"
                ></path>
                <g id="Ellipse 3_2" filter="url(#filter3_d_18_101)">
                  <path
                    fill="url(#paint42_linear_18_101)"
                    d="M352.631 114.736c-14.039-9.115-22.303-9.224-33.958-5.886 6.594-4.024 14.755-11.105 33.958 5.886z"
                    shapeRendering="crispEdges"
                  ></path>
                </g>
                <g id="Vector 3_2" filter="url(#filter4_f_18_101)">
                  <path
                    fill="url(#paint43_linear_18_101)"
                    d="M325.576 116.475c16.209 9.791 24.783 2.844 27.044-1.854-10.183 1.909-22.877-1.284-27.951-3.12-6.451-2.422-15.301-4.818.907 4.974z"
                  ></path>
                </g>
                <path
                  id="Vector 1_2"
                  fill="url(#paint44_linear_18_101)"
                  d="M309.29 110.027c6.774-4.37 22.301-11.663 30.219-5.87-.614-.164-5.121-1.498-6.274-.623.024.401 3.116 1.62 2.977 2.635-.139 1.014-5.817 3.574-8.369 1.316-.188.212-.679 2.053-1.505 1.701-.811-.345 5.688 7.968 7.423 7.807l.297-.081c.053-.016.056-.018 0 0a7.515 7.515 0 00-.203.063.474.474 0 01-.094.018c-1.216.321-5.388 1.289-7.146.022-.223.706-.52 2.019-.3 2.669.294.269.41.403.284.349-.136-.058-.227-.181-.284-.349-1.623-1.484-8.664-7.073-10.063-7.669-1.322-.563-5.192-1.56-6.962-1.988z"
                ></path>
                <g id="Vector 2_2" filter="url(#filter5_f_18_101)">
                  <path
                    fill="url(#paint45_linear_18_101)"
                    d="M336.449 106.758c-5.426-1.01-16.668-2.16-18.226 1.315.278 1.35 9.528 6.706 13.995 8.063.244.022.382.054.385.11-.124-.033-.252-.07-.385-.11-1.494-.132-6.991.137-10.421-2.247-.151-.134 1.831 2.964 3.275 4.744.799.662 1.308 1.121 1.314 1.212-.231.014-.728-.491-1.314-1.212-2.7-2.239-8.705-6.808-9.671-7.37-1.002-.583-3.531-.723-4.671-.721l-.262-.99c3.564-2.028 10.933-6.099 11.895-6.158 1.202-.073 11.534 1.106 14.086 3.364z"
                  ></path>
                </g>
              </g>
            </motion.g>
          </g>
          <g id="florCojollo2_2">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 1, 2, 3, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <path
                id="tallo_3"
                fill="#91705B"
                d="M293.373 106.5c13.5-8.5 11-14 11.5-20-3.5 10-4 12-12.897 18.45.377.485 1.184 1.474 1.397 1.55z"
              ></path>
              <g id="petalos_3">
                <path
                  id="Ellipse 1_3"
                  fill="url(#paint46_radial_18_101)"
                  d="M321.064 71.943c1.892 1.522-.262 8.362-3.722 12.661-3.46 4.299-7.47 4.563-9.361 3.04-1.892-1.522-4.74-2.417-2.222-8.102 2.826-4.733 13.414-9.122 15.305-7.6z"
                ></path>
                <path
                  id="Ellipse 2_3"
                  fill="url(#paint47_linear_18_101)"
                  d="M320.572 71.665c-10.742 2.423-13.522 7.494-17.16 14.984-.221.606-.477 1.165-.805 1.66l.805-1.66c1.714-4.695 1.39-12.228 17.16-14.984z"
                ></path>
                <g id="Ellipse 3_3" filter="url(#filter6_d_18_101)">
                  <path
                    fill="url(#paint48_linear_18_101)"
                    d="M321.468 71.68c-10.751 4.816-13.972 9.66-16.452 17.823.141-5.434-.927-12.963 16.452-17.823z"
                    shapeRendering="crispEdges"
                  ></path>
                </g>
                <g id="Vector 3_3" filter="url(#filter7_f_18_101)">
                  <path
                    fill="url(#paint49_linear_18_101)"
                    d="M312.16 88.336c11.98-5.84 11.149-13.562 9.236-16.693-2.762 6.748-9.499 13.031-12.522 15.329-3.896 2.888-8.694 7.203 3.286 1.364z"
                  ></path>
                </g>
                <path
                  id="Vector 1_3"
                  fill="url(#paint50_linear_18_101)"
                  d="M302.127 95.499c.005-5.674 1.626-17.637 8.075-20.104-.331.3-2.842 2.455-2.765 3.47.246.14 2.148-1.222 2.694-.753.547.47-.109 4.804-2.419 5.45.054.192.955 1.185.431 1.54-.514.346 6.882-.32 7.45-1.406.032-.098.054-.17.065-.206.012-.038.012-.04 0 0a11.04 11.04 0 00-.04.144.319.319 0 01-.025.062c-.275.841-1.297 3.677-2.717 4.232.332.402.995 1.079 1.463 1.197.271-.071.395-.088.315-.034-.086.058-.194.065-.315.034-1.497.392-7.49 2.42-8.377 3.019-.838.566-2.905 2.472-3.835 3.355z"
                ></path>
                <g id="Vector 2_3" filter="url(#filter8_f_18_101)">
                  <path
                    fill="url(#paint51_linear_18_101)"
                    d="M310.57 78.197c-2.67 2.822-7.644 9.027-6.185 11.275.904.352 7.603-3.07 10.112-5.191.106-.137.178-.205.212-.186-.067.06-.137.123-.212.186-.649.832-2.59 4.184-5.309 5.3-.137.039 2.451.05 4.055-.123.696-.219 1.162-.345 1.218-.313-.08.142-.568.243-1.218.313-2.355.74-7.35 2.545-8.051 2.901-.727.37-1.776 1.811-2.21 2.486l-.685-.224c.163-2.881.571-8.792.904-9.383.416-.739 5.059-6.395 7.369-7.04z"
                  ></path>
                </g>
              </g>
            </motion.g>
          </g>
          <g id="florCojollo1_2">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 1, 2, 3, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <path
                id="tallo_4"
                fill="#91705B"
                d="M255.605 141.624c56.123-60.954 79.577-42.067 84.459-49.188-41.508-9.031-78.042 38.371-83.986 44.569-.238 1.346-.666 4.154-.473 4.619z"
              ></path>
              <g id="petalos_4">
                <path
                  id="Ellipse 1_4"
                  fill="url(#paint52_radial_18_101)"
                  d="M389.688 80.764c1.215 5.264-11.958 14.267-23.922 17.029-11.965 2.762-19.342-2.29-20.557-7.554-1.215-5.265-4.965-10.747 7.255-17.228 11.453-4.393 36.009 2.488 37.224 7.753z"
                ></path>
                <path
                  id="Ellipse 2_4"
                  fill="url(#paint53_linear_18_101)"
                  d="M389.208 79.601c-22.121-10.532-33.958-5.486-50.622 2.614-1.22.756-2.435 1.382-3.69 1.797 1.259-.612 2.487-1.212 3.69-1.797 9.454-5.856 19.246-19.48 50.622-2.614z"
                ></path>
                <g id="Ellipse 3_4" filter="url(#filter9_d_18_101)">
                  <path
                    fill="url(#paint54_linear_18_101)"
                    d="M390.755 80.86c-25.429-6.36-37.724-2.318-53.287 8.553 7.719-9.313 16.204-23.95 53.287-8.553z"
                    shapeRendering="crispEdges"
                  ></path>
                </g>
                <g id="Vector 3_4" filter="url(#filter10_f_18_101)">
                  <path
                    fill="url(#paint55_linear_18_101)"
                    d="M351.57 97.195c28.986 6.258 38.151-8.393 39.11-16.501-14.11 8.007-34.535 9.734-42.984 9.596-10.786-.306-25.112.646 3.874 6.904z"
                  ></path>
                </g>
                <path
                  id="Vector 1_4"
                  fill="url(#paint56_linear_18_101)"
                  d="M324.17 95.928c7.81-9.917 27.096-28.614 41.769-24.063-.992.07-8.346.387-9.609 2.27.24.58 5.44.814 5.75 2.387.309 1.573-6.797 8.252-11.725 6.205-.171.411.038 3.387-1.364 3.286-1.377-.1 12.476 8.907 14.964 7.787.191-.128.328-.222.398-.272.072-.05.075-.054 0 0-.052.037-.14.101-.268.197a.724.724 0 01-.13.075c-1.637 1.094-7.324 4.649-10.572 3.666.029 1.16.257 3.255.914 4.106.571.249.811.388.597.373-.23-.017-.428-.153-.597-.373-3.159-1.373-16.43-6.068-18.806-6.239-2.244-.162-8.482.33-11.321.595z"
                ></path>
                <g id="Vector 2_4" filter="url(#filter11_f_18_101)">
                  <path
                    fill="url(#paint57_linear_18_101)"
                    d="M362.73 77.274c-8.55 1.264-25.784 5.278-26.324 11.217 1.099 1.859 17.522 5.086 24.828 4.824.373-.092.593-.114.626-.032-.2.014-.409.025-.626.032-2.28.565-10.284 3.758-16.576 1.972-.292-.12 4.219 3.46 7.263 5.361 1.519.574 2.507.994 2.562 1.126-.336.139-1.328-.356-2.562-1.126-5.139-1.944-16.356-5.656-18.073-5.997-1.779-.354-5.597.725-7.284 1.309l-.891-1.333c4.247-4.817 13.09-14.594 14.485-15.17 1.743-.721 17.644-4.23 22.572-2.183z"
                  ></path>
                </g>
              </g>
              <path
                id="Vector 5_4"
                fill="#91705B"
                d="M180.233 210c14.878-42.755 88.651-78.384 76.872-76.959-81.212 48.456-75.633 58.669-78.732 69.833.413 2.138 1.364 6.556 1.86 7.126z"
              ></path>
            </motion.g>
          </g>
          <g id="Flor_rama_3">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 3, 4, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <path
                id="Vector 5_5"
                fill="#91705B"
                d="M180.115 210.021c12-30 71.5-55 62-54-65.5 34-61 41.167-63.5 49 .333 1.5 1.1 4.6 1.5 5z"
              ></path>
              <g id="Flor_3">
                <path
                  id="petalo-fondo_3"
                  fill="url(#paint58_linear_18_101)"
                  d="M241.114 155.992c-.666-7.333-1.6-22.8 0-26 2-4 6.501-28.5 19.001-29.5 6.997.5 19.49-.999 11.009 14.982.654-.334 17.209-5.146 23.491 4.518 6.5 10 3 20 1.5 24.5 3.5 1.5 14.5 15 12.5 25.5-1-.5-5.5 23.5-20.5 15.5 0 .5 0 7.5-1.5 9s-17.5 0-22.5-5c-4-4-14.334-19.667-19-27l-4.001-6.5z"
                ></path>
                <path
                  id="Vector 9_3"
                  fill="url(#paint59_radial_18_101)"
                  stroke="url(#paint60_linear_18_101)"
                  d="M289.615 185.992c-15.2 3.2-38-19-47.5-30.5 12.166-3.667 38.7-10.9 47.5-10.5 8.8.4 16.333 15.833 19 23.5 0 4.5-3.8 14.3-19 17.5z"
                ></path>
                <path
                  id="Vector 10_3"
                  fill="url(#paint61_linear_18_101)"
                  stroke="url(#paint62_linear_18_101)"
                  d="M285.115 195.492c-34-1.2-43.5-25.167-44-37l37.5 18.5c5.333 5.667 14.1 17.3 6.5 18.5z"
                ></path>
                <path
                  id="Vector 8_3"
                  fill="url(#paint63_radial_18_101)"
                  stroke="url(#paint64_linear_18_101)"
                  d="M293.115 146.492c-2.8 2.8-35.834 8.167-52 10.5l14.5-25c1.833-2.333 7-8.6 13-15 7.5-8 23 0 27 3.5s1.5 14.5 0 16 1 6.5-2.5 10z"
                ></path>
                <path
                  id="petalo_7"
                  fill="url(#paint65_radial_18_101)"
                  d="M265.615 159.992c-6.4-8-18.667-6-24-4 .666 1.333 2 4.5 2 6.5s16.333 18.5 24 26c4-4 4.4-20.5-2-28.5z"
                ></path>
                <path
                  id="petalo_8"
                  fill="url(#paint66_radial_18_101)"
                  stroke="url(#paint67_linear_18_101)"
                  d="M236.615 109.992c-14.4 11.6-3 35.5 4.5 46 0 0 2-14.5 2-20.5s-2.5-25.5-6.5-25.5z"
                ></path>
                <path
                  id="Vector 6_3"
                  fill="url(#paint68_radial_18_101)"
                  stroke="#F3C2B4"
                  d="M260.115 100.492c-19.6 10.4-20.167 41.333-18 55.5 1.5-19.5 28.5-37 31.5-42s-.5-16.5-13.5-13.5z"
                ></path>
                <path
                  id="Vector 7_3"
                  fill="url(#paint69_radial_18_101)"
                  stroke="url(#paint70_linear_18_101)"
                  d="M270.115 146.492c-8 9.2-22.334 10.833-28.5 10.5 0-28.5 1.5-26.5 4-31 2.145-3.862 12.5-6.833 18-8.5 1 3.333 3.2 10.5 4 12.5 1 2.5 6.5 8 2.5 16.5z"
                ></path>
                <path
                  id="Vector 5_6"
                  fill="url(#paint71_radial_18_101)"
                  stroke="url(#paint72_linear_18_101)"
                  d="M250.115 141.992c-.4 2.4-5.5 10.667-8 14.5-2.5-28 1.5-27.5 8-35 21-12.5 14 3 9.5 3.5s-9 14-9.5 17z"
                ></path>
                <path
                  id="petalo_9"
                  fill="url(#paint73_radial_18_101)"
                  stroke="url(#paint74_linear_18_101)"
                  d="M266.615 189.492c-9.259 3-30-21.392-24.589-35.148 0 0-.967 2.726 3.089 7.148 4.055 4.422 34 16.5 21.5 28z"
                ></path>
              </g>
            </motion.g>
          </g>
          <g id="florCojollo2_3">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                rotate: [0, 4, 5, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut"
              }}
            >
              <path
                id="tallo_5"
                fill="#91705B"
                d="M43.214 419.855c26.521-47.484 88.895-66.486 108.161-72.593-71.69 10.183-104.37 57.038-109.004 66.155.11 1.908.432 5.867.843 6.438z"
              ></path>
              <g id="petalos_5">
                <path
                  id="Ellipse 1_5"
                  fill="url(#paint75_radial_18_101)"
                  d="M212.099 330.226c2.158 7.241-15.44 20.964-31.898 25.868-16.459 4.905-27.199-1.499-29.357-8.741-2.158-7.241-7.873-14.565 8.615-24.684 15.602-7.136 50.482.315 52.64 7.557z"
                ></path>
                <path
                  id="Ellipse 2_5"
                  fill="url(#paint76_linear_18_101)"
                  d="M211.327 328.645c-31.802-12.761-47.882-4.679-70.432 8.09-1.637 1.162-3.278 2.143-4.993 2.832 1.704-.965 3.365-1.911 4.993-2.832 12.683-9.004 25.156-28.88 70.432-8.09z"
                ></path>
                <g id="Ellipse 3_5" filter="url(#filter12_d_18_101)">
                  <path
                    fill="url(#paint77_linear_18_101)"
                    d="M213.596 330.266c-36.052-6.647-52.86.075-73.631 16.614 9.958-13.677 20.518-34.854 73.631-16.614z"
                    shapeRendering="crispEdges"
                  ></path>
                </g>
                <g id="Vector 3_5" filter="url(#filter13_f_18_101)">
                  <path
                    fill="url(#paint78_linear_18_101)"
                    d="M160.333 356.505c41.009 6.192 52.516-15.062 53.144-26.464-18.993 12.416-47.352 16.617-59.157 17.166-15.083.519-34.997 3.105 6.013 9.298z"
                  ></path>
                </g>
                <path
                  id="Vector 1_5"
                  fill="url(#paint79_linear_18_101)"
                  d="M121.976 357.141c10.031-14.529 35.312-42.319 56.192-37.253-1.378.184-11.615 1.272-13.213 4.011.386.79 7.664.659 8.234 2.828.571 2.168-8.763 12.114-15.821 9.69-.202.588.35 4.724-1.616 4.706-1.93-.018 18.197 11.339 21.571 9.556a25.8 25.8 0 00.532-.414c.095-.076.1-.082 0 0-.069.057-.187.154-.358.298a.976.976 0 01-.174.116c-2.19 1.671-9.816 7.133-14.435 6.046.141 1.616.644 4.521 1.635 5.651.819.297 1.167.471.866.468-.323-.003-.61-.176-.866-.468-4.529-1.639-23.466-7.029-26.797-7.059-3.146-.029-11.811 1.204-15.75 1.824z"
                ></path>
                <g id="Vector 2_5" filter="url(#filter14_f_18_101)">
                  <path
                    fill="url(#paint80_linear_18_101)"
                    d="M174.164 327.719c-11.824 2.515-35.528 9.63-35.761 17.967 1.697 2.498 24.904 5.562 35.079 4.556.513-.162.818-.212.872-.101-.278.038-.569.071-.872.101-3.132.988-14.024 6.147-22.964 4.207-.419-.144 6.192 4.458 10.608 6.845 2.171.668 3.587 1.168 3.675 1.348-.456.223-1.885-.381-3.675-1.348-7.343-2.262-23.327-6.46-25.753-6.785-2.514-.338-7.749 1.503-10.052 2.466l-1.361-1.783c5.506-7.096 16.991-21.519 18.887-22.446 2.371-1.159 24.258-7.451 31.317-5.027z"
                  ></path>
                </g>
              </g>
            </motion.g>
          </g>
          <g id="florCojollo1_3">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 2, 3, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <path
                id="tallo_6"
                fill="#91705B"
                d="M38.873 426.5c32-87.5 64.5-78 66.5-87.5-47 8.52-64.5 73.5-68 82.5.333 1.5 1.1 4.6 1.5 5z"
              ></path>
              <g id="petalos_6">
                <path
                  id="Ellipse 1_6"
                  fill="url(#paint81_radial_18_101)"
                  d="M151.873 305.48c3.532 4.943-6.266 19.973-17.5 28-11.234 8.028-21.073 5.962-24.605 1.02-3.532-4.943-9.79-9.02.105-21.02 10-9.5 38.468-12.943 42-8z"
                ></path>
                <path
                  id="Ellipse 2_6"
                  fill="url(#paint82_linear_18_101)"
                  d="M150.873 304.48c-27.514-1.399-37.63 8.946-51.443 24.543-.942 1.311-1.933 2.486-3.057 3.457l3.057-3.457c7.293-10.158 11.587-28.528 51.443-24.543z"
                ></path>
                <g id="Ellipse 3_6" filter="url(#filter15_d_18_101)">
                  <path
                    fill="url(#paint83_linear_18_101)"
                    d="M153.022 305.12c-29.149 4.36-40.175 13.86-51.649 31.86 4-13 6.5-31.86 51.649-31.86z"
                    shapeRendering="crispEdges"
                  ></path>
                </g>
                <g id="Vector 3_6" filter="url(#filter16_f_18_101)">
                  <path
                    fill="url(#paint84_linear_18_101)"
                    d="M119.373 338.98c32.8-6 36-25.166 33.5-34-11.2 14.4-31.667 25-40.5 28.5-11.333 4.334-25.8 11.5 7 5.5z"
                  ></path>
                </g>
                <path
                  id="Vector 1_6"
                  fill="url(#paint85_linear_18_101)"
                  d="M90.373 349.48c3.833-13.666 15.8-41.4 33-43-1 .5-8.5 4-9 6.5.5.5 6-1.5 7 0s-3.5 11.5-9.5 11.5c0 .5 1.5 3.5 0 4-1.472.491 16.797 3.871 18.898 1.634.143-.215.244-.372.296-.454.052-.083.055-.088 0 0a21.86 21.86 0 00-.194.32.754.754 0 01-.102.134c-1.229 1.842-5.602 7.987-9.398 8.366.53 1.193 1.671 3.27 2.719 3.871.701.011 1.011.053.781.129-.246.082-.51.026-.781-.129-3.872-.064-19.679.782-22.219 1.629-2.4.8-8.667 4-11.5 5.5z"
                ></path>
                <g id="Vector 2_6" filter="url(#filter17_f_18_101)">
                  <path
                    fill="url(#paint86_linear_18_101)"
                    d="M122.373 313.48c-8.334 5-24.5 16.6-22.5 23 1.942 1.457 20.389-2.273 27.863-5.696.348-.256.566-.374.637-.304-.202.101-.415.203-.637.304-2.124 1.57-9.059 8.337-16.363 9.196-.356 0 5.872 1.773 9.854 2.435 1.825-.059 3.032-.049 3.146.065-.289.289-1.533.203-3.146-.065-6.175.198-19.425 1.179-21.354 1.565-2 .4-5.5 3.167-7 4.5l-1.5-1c2.333-6.833 7.3-20.8 8.5-22 1.5-1.5 16.5-12 22.5-12z"
                  ></path>
                </g>
              </g>
            </motion.g>
          </g>
          <g id="Flor_frontal">
            <motion.g
              style={{
                originX: "0px",
                originY: "413px",
              }}
              animate={{
                // animationDelay:10,
                rotate: [0, 2, 3, 0]
              }}
              transition={{
                loop: Infinity,
                duration: 5,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            >
              <path
                id="tallo_7"
                fill="#91705B"
                d="M39.873 417.5c32.574-72.173 42.5-128 63.5-140.5-9.5 25-37.528 102.5-58.5 138.5-1.516.252-4.48 2.221-5 2z"
              ></path>
              <path
                id="Vector 14"
                fill="url(#paint87_diamond_18_101)"
                stroke="url(#paint88_linear_18_101)"
                d="M138.803 283c2.4-5.6 7-36 0-49-60.5-3-63.5 58-46 72 27 5 43-16 46-23z"
              ></path>
              <path
                id="Vector 15"
                fill="url(#paint89_diamond_18_101)"
                stroke="url(#paint90_linear_18_101)"
                d="M177.303 327c-53.2-11.2-49.167-65-40.5-90.5l48 12c13.667 13.833 30.9 59.7-7.5 78.5z"
              ></path>
              <path
                id="Vector 13"
                fill="url(#paint91_diamond_18_101)"
                stroke="url(#paint92_linear_18_101)"
                d="M144.303 236c-43 16-92.5 3.5-75.5-39.5 46.5-50 72 34 75.5 39.5z"
              ></path>
              <path
                id="Vector 12"
                fill="url(#paint93_radial_18_101)"
                stroke="url(#paint94_linear_18_101)"
                d="M145.25 242.091c-58.096-36.961-35.657-114.425 2.182-87.473 26.927-36.84 75.567 58.403-2.182 87.473z"
              ></path>
              <path
                id="Vector 17"
                fill="url(#paint95_diamond_18_101)"
                stroke="url(#paint96_linear_18_101)"
                d="M145.858 229.622c43.118-53.686 117.649-22.874 86.704 11.776 33.66 30.811-66.349 68.695-86.704-11.776z"
              ></path>
              <g id="Vector 16" filter="url(#filter18_f_18_101)">
                <path
                  fill="url(#paint97_radial_18_101)"
                  d="M123.373 212c7.666 13.667 23.4 32.8 25 0 8 25.6 13 14 20 7-5 9-12 29 0 39-20-3-26-3-24 6-8-6-7-25-21-18 6.527-6.527-2.342-9.165-9.024-9.849a16.964 16.964 0 01-1.976-.151 38.56 38.56 0 011.976.151c7.333.135 17.072-4.397 9.024-24.151z"
                ></path>
              </g>
              <path
                id="Vector 18"
                fill="url(#paint98_radial_18_101)"
                d="M133.873 224c3.833 6.833 11.7 16.4 12.5 0 4 12.8 6.5 7 10 3.5-2.5 4.5-6 14.5 0 19.5-10-1.5-13-1.5-12 3-4-3-3.5-12.5-10.5-9 3.263-3.264-1.172-4.582-4.512-4.924a8.573 8.573 0 01-.988-.076c.311.016.643.04.988.076 3.666.067 8.536-2.199 4.512-12.076z"
              ></path>
              <g id="semillas">
                <circle
                  id="Ellipse 4"
                  cx="150.373"
                  cy="238"
                  r="2"
                  fill="url(#paint99_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 5"
                  cx="149.373"
                  cy="235"
                  r="2"
                  fill="url(#paint100_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 6"
                  cx="145.373"
                  cy="232"
                  r="2"
                  fill="url(#paint101_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 7"
                  cx="140.373"
                  cy="232"
                  r="2"
                  fill="url(#paint102_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 8"
                  cx="138.373"
                  cy="235"
                  r="2"
                  fill="url(#paint103_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 9"
                  cx="142.373"
                  cy="237"
                  r="2"
                  fill="url(#paint104_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 10"
                  cx="145.373"
                  cy="235"
                  r="2"
                  fill="url(#paint105_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 11"
                  cx="145.373"
                  cy="240"
                  r="2"
                  fill="url(#paint106_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 12"
                  cx="141.373"
                  cy="240"
                  r="2"
                  fill="url(#paint107_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 13"
                  cx="145.373"
                  cy="243"
                  r="2"
                  fill="url(#paint108_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 14"
                  cx="149.373"
                  cy="243"
                  r="2"
                  fill="url(#paint109_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 15"
                  cx="148.373"
                  cy="240"
                  r="2"
                  fill="url(#paint110_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 16"
                  cx="141.373"
                  cy="238"
                  r="2"
                  fill="url(#paint111_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 17"
                  cx="137.373"
                  cy="237"
                  r="2"
                  fill="url(#paint112_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 18"
                  cx="134.373"
                  cy="233"
                  r="2"
                  fill="url(#paint113_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 19"
                  cx="141.373"
                  cy="234"
                  r="2"
                  fill="url(#paint114_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 20"
                  cx="144.373"
                  cy="234"
                  r="2"
                  fill="url(#paint115_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 21"
                  cx="148.373"
                  cy="238"
                  r="2"
                  fill="url(#paint116_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 22"
                  cx="141.373"
                  cy="242"
                  r="2"
                  fill="url(#paint117_radial_18_101)"
                ></circle>
                <circle
                  id="Ellipse 23"
                  cx="139.373"
                  cy="242"
                  r="2"
                  fill="url(#paint118_radial_18_101)"
                ></circle>
              </g>
            </motion.g>
          </g>
        </motion.g>
      </g>
      <defs>
        <filter
          id="filter0_d_18_101"
          width="26.735"
          height="58.496"
          x="195.798"
          y="95.516"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.952941 0 0 0 0 0.882353 0 0 0 0 0.843137 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18_101"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_18_101"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_f_18_101"
          width="25.523"
          height="57.002"
          x="191.978"
          y="93.608"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter2_f_18_101"
          width="31.124"
          height="40.9"
          x="187.084"
          y="118.836"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter3_d_18_101"
          width="41.958"
          height="18.83"
          x="314.673"
          y="103.906"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.952941 0 0 0 0 0.882353 0 0 0 0 0.843137 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18_101"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_18_101"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_f_18_101"
          width="40.24"
          height="16.076"
          x="314.38"
          y="107.355"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter5_f_18_101"
          width="31.981"
          height="22.454"
          x="307.468"
          y="100.391"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter6_d_18_101"
          width="24.452"
          height="25.823"
          x="301.016"
          y="71.68"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.952941 0 0 0 0 0.882353 0 0 0 0 0.843137 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18_101"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_18_101"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter7_f_18_101"
          width="21.965"
          height="23.592"
          x="302.55"
          y="69.643"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter8_f_18_101"
          width="18.412"
          height="22.647"
          x="299.297"
          y="75.197"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter9_d_18_101"
          width="61.287"
          height="23.268"
          x="333.468"
          y="74.145"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.952941 0 0 0 0 0.882353 0 0 0 0 0.843137 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18_101"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_18_101"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter10_f_18_101"
          width="60.207"
          height="22.014"
          x="332.473"
          y="78.694"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter11_f_18_101"
          width="43.057"
          height="31.157"
          x="322.673"
          y="73.641"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter12_d_18_101"
          width="81.631"
          height="31.695"
          x="135.966"
          y="323.185"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.952941 0 0 0 0 0.882353 0 0 0 0 0.843137 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18_101"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_18_101"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter13_f_18_101"
          width="81.494"
          height="31.572"
          x="133.983"
          y="328.041"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter14_f_18_101"
          width="56.394"
          height="41.52"
          x="120.96"
          y="324.168"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter15_d_18_101"
          width="59.65"
          height="39.86"
          x="97.373"
          y="305.12"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.952941 0 0 0 0 0.882353 0 0 0 0 0.843137 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18_101"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_18_101"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter16_f_18_101"
          width="58.33"
          height="40.296"
          x="97.208"
          y="302.98"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter17_f_18_101"
          width="43"
          height="41"
          x="88.373"
          y="310.48"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="1.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter18_f_18_101"
          width="76"
          height="72"
          x="102.373"
          y="202"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_18_101"
            stdDeviation="5"
          ></feGaussianBlur>
        </filter>
        <radialGradient
          id="paint0_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-12.47626 -4.78869 19.51401 -50.84104 209.521 118.277)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.385" stopColor="#F8EDEB"></stop>
          <stop offset="1" stopColor="#E3AF80"></stop>
        </radialGradient>
        <linearGradient
          id="paint1_linear_18_101"
          x1="220.236"
          x2="206.903"
          y1="126.942"
          y2="121.825"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0B191"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_18_101"
          x1="217.794"
          x2="202.307"
          y1="126.783"
          y2="117.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAA88"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_18_101"
          x1="192.798"
          x2="209.077"
          y1="119.044"
          y2="121.115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9A46C"></stop>
          <stop offset="1" stopColor="#E1CEC0" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_18_101"
          x1="191.889"
          x2="205.034"
          y1="158.858"
          y2="117.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E603B"></stop>
          <stop offset="0.349" stopColor="#6E603B"></stop>
          <stop offset="0.797" stopColor="#E5CEA5"></stop>
          <stop offset="1" stopColor="#E6D8BD"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_18_101"
          x1="192.294"
          x2="209.325"
          y1="156.954"
          y2="113.405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#584728"></stop>
          <stop offset="1" stopColor="#7A6642" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_18_101"
          x1="263.623"
          x2="281.31"
          y1="98.059"
          y2="63.414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8D5518"></stop>
          <stop offset="0.254" stopColor="#BC8040"></stop>
          <stop offset="1" stopColor="#F7C6BB"></stop>
        </linearGradient>
        <radialGradient
          id="paint7_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-40.005 261.72 -315.305) scale(39.754 77.582)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.337" stopColor="#B78C5B"></stop>
          <stop offset="0.666" stopColor="#F5D2B6" stopOpacity="0.688"></stop>
          <stop offset="1" stopColor="#FBD6CE" stopOpacity="0.33"></stop>
        </radialGradient>
        <linearGradient
          id="paint8_linear_18_101"
          x1="263.277"
          x2="280.512"
          y1="92.817"
          y2="68.494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#EDB494" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_18_101"
          x1="262.167"
          x2="299.018"
          y1="96.205"
          y2="83.813"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.125" stopColor="#895621"></stop>
          <stop offset="0.844" stopColor="#F4C8B1"></stop>
          <stop offset="1" stopColor="#EBC8A2"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_18_101"
          x1="262.167"
          x2="295.681"
          y1="96.205"
          y2="83.338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A95D50"></stop>
          <stop offset="1" stopColor="#A95D50" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint11_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-91.305 177.577 -79.959) scale(43.6877 21.5064)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.276" stopColor="#BC7C35"></stop>
          <stop offset="0.63" stopColor="#FCEDDC" stopOpacity="0.79"></stop>
          <stop offset="1" stopColor="#F4C3B6"></stop>
        </radialGradient>
        <linearGradient
          id="paint12_linear_18_101"
          x1="261.559"
          x2="271.911"
          y1="95.751"
          y2="60.683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint13_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-80.274 187.97 -108.058) scale(15.86 10.2845)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.078" stopColor="#82511F"></stop>
          <stop offset="0.286" stopColor="#BA7B3A"></stop>
          <stop offset="1" stopColor="#F8DCC7"></stop>
        </radialGradient>
        <radialGradient
          id="paint14_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-22.47628 -20.621 7.4737 -8.14611 259.99 91.512)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.378" stopColor="#B77632"></stop>
          <stop offset="0.74" stopColor="#FDD6B7"></stop>
          <stop offset="1" stopColor="#F1DAD5"></stop>
        </radialGradient>
        <linearGradient
          id="paint15_linear_18_101"
          x1="260.831"
          x2="234.901"
          y1="94.824"
          y2="85.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#734C12"></stop>
          <stop offset="1" stopColor="#D1914D" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint16_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-120.497 157.71 -26.93) scale(46.0697 11.4942)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05" stopColor="#B17A39"></stop>
          <stop offset="0.749" stopColor="#F6DCC9"></stop>
          <stop offset="1" stopColor="#F2C1B2"></stop>
        </radialGradient>
        <radialGradient
          id="paint17_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-11.49232 -23.91665 12.41733 -5.96672 261.559 95.75)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.13" stopColor="#C59B6C"></stop>
          <stop offset="0.422" stopColor="#FCDAB1"></stop>
          <stop offset="1" stopColor="#F9CDBF"></stop>
        </radialGradient>
        <linearGradient
          id="paint18_linear_18_101"
          x1="261.559"
          x2="269.756"
          y1="95.751"
          y2="71.689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A95D50"></stop>
          <stop offset="1" stopColor="#A95D50" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint19_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(172.918 130.138 47.648) scale(22.6144 23.189)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.063" stopColor="#895621"></stop>
          <stop offset="0.406" stopColor="#B47832"></stop>
          <stop offset="0.828" stopColor="#F5C49D" stopOpacity="0.52"></stop>
          <stop offset="1" stopColor="#FCEBDD" stopOpacity="0.84"></stop>
        </radialGradient>
        <linearGradient
          id="paint20_linear_18_101"
          x1="260.951"
          x2="251.433"
          y1="95.297"
          y2="64.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#995F22"></stop>
          <stop offset="0.25" stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint21_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(15.459 -205.224 1014.202) scale(32.979 8.32452)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.177" stopColor="#B77632"></stop>
          <stop offset="0.807" stopColor="#E6CBB6" stopOpacity="0.85"></stop>
          <stop offset="1" stopColor="#F1DFD5"></stop>
        </radialGradient>
        <linearGradient
          id="paint22_linear_18_101"
          x1="263.927"
          x2="283.328"
          y1="98.286"
          y2="94.342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint23_linear_18_101"
          x1="144.737"
          x2="121.97"
          y1="164.551"
          y2="86.215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8D5518"></stop>
          <stop offset="0.254" stopColor="#BC8040"></stop>
          <stop offset="0.557" stopColor="#FDD3C9"></stop>
          <stop offset="1" stopColor="#FFE6E0"></stop>
        </linearGradient>
        <radialGradient
          id="paint24_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-83.255 159.26 .502) scale(83.3711 162.703)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.337" stopColor="#B78C5B"></stop>
          <stop offset="0.505" stopColor="#fff" stopOpacity="0.688"></stop>
          <stop offset="0.859" stopColor="#FDF0ED" stopOpacity="0.558"></stop>
          <stop offset="1" stopColor="#FBD6CE" stopOpacity="0.33"></stop>
        </radialGradient>
        <linearGradient
          id="paint25_linear_18_101"
          x1="136.675"
          x2="128.052"
          y1="157.041"
          y2="95.122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#EDB494" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint26_linear_18_101"
          x1="139.848"
          x2="178.333"
          y1="163.811"
          y2="91.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.125" stopColor="#895621"></stop>
          <stop offset="0.448" stopColor="#EDDFD2"></stop>
          <stop offset="0.698" stopColor="#FFF7F2"></stop>
          <stop offset="0.844" stopColor="#F4C8B1"></stop>
          <stop offset="1" stopColor="#EBC8A2"></stop>
        </linearGradient>
        <linearGradient
          id="paint27_linear_18_101"
          x1="139.848"
          x2="172.553"
          y1="163.811"
          y2="95.999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A95D50"></stop>
          <stop offset="1" stopColor="#A95D50" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint28_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-64.28057 -65.2869 32.13909 -31.6437 138.267 163.991)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.276" stopColor="#BC7C35"></stop>
          <stop offset="0.5" stopColor="#FFFAF4" stopOpacity="0.824"></stop>
          <stop offset="0.672" stopColor="#FEF6EC" stopOpacity="0.813"></stop>
          <stop offset="0.859" stopColor="#FCEDDC" stopOpacity="0.79"></stop>
          <stop offset="1" stopColor="#F4C3B6"></stop>
        </radialGradient>
        <linearGradient
          id="paint29_linear_18_101"
          x1="138.267"
          x2="103.689"
          y1="163.991"
          y2="95.551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint30_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-18.3697 -27.72835 17.98068 -11.91199 139.629 161.891)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.078" stopColor="#82511F"></stop>
          <stop offset="0.286" stopColor="#BA7B3A"></stop>
          <stop offset="0.932" stopColor="#F8F4F0"></stop>
          <stop offset="1" stopColor="#F8DCC7"></stop>
        </radialGradient>
        <radialGradient
          id="paint31_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(179.285 64.392 80.29) scale(63.9692 23.1845)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.378" stopColor="#B77632"></stop>
          <stop offset="0.74" stopColor="#FDD6B7"></stop>
          <stop offset="1" stopColor="#F1DAD5"></stop>
        </radialGradient>
        <linearGradient
          id="paint32_linear_18_101"
          x1="135.823"
          x2="82.286"
          y1="163.621"
          y2="186.074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#734C12"></stop>
          <stop offset="1" stopColor="#D1914D" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint33_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-163.748 80.064 72.327) scale(96.6162 24.1053)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05" stopColor="#B17A39"></stop>
          <stop offset="0.749" stopColor="#FFF6EF"></stop>
          <stop offset="1" stopColor="#F2C1B2"></stop>
        </radialGradient>
        <radialGradient
          id="paint34_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-158.915 84.393 69.13) scale(55.6475 28.8917)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.13" stopColor="#C59B6C"></stop>
          <stop offset="0.422" stopColor="#FCDAB1"></stop>
          <stop offset="0.776" stopColor="#fff"></stop>
          <stop offset="1" stopColor="#F9CDBF"></stop>
        </radialGradient>
        <linearGradient
          id="paint35_linear_18_101"
          x1="138.267"
          x2="116.212"
          y1="163.991"
          y2="115.457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A95D50"></stop>
          <stop offset="1" stopColor="#A95D50" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint36_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(129.668 28.507 94.612) scale(47.4264 48.6315)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.063" stopColor="#895621"></stop>
          <stop offset="0.406" stopColor="#B47832"></stop>
          <stop offset="0.828" stopColor="#F5C49D" stopOpacity="0.52"></stop>
          <stop offset="1" stopColor="#FCEBDD" stopOpacity="0.84"></stop>
        </radialGradient>
        <linearGradient
          id="paint37_linear_18_101"
          x1="136.686"
          x2="77.893"
          y1="164.171"
          y2="130.804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#995F22"></stop>
          <stop offset="0.25" stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint38_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(61.18516 -32.24658 8.13967 15.44434 134.086 155.392)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.177" stopColor="#B77632"></stop>
          <stop offset="0.807" stopColor="#FDF4ED" stopOpacity="0.85"></stop>
          <stop offset="1" stopColor="#FFFBF9"></stop>
        </radialGradient>
        <linearGradient
          id="paint39_linear_18_101"
          x1="145.527"
          x2="169.496"
          y1="164.461"
          y2="130.558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint40_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(95.955 118.646 207.84) scale(8.717 35.522)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.385" stopColor="#F8EDEB"></stop>
          <stop offset="1" stopColor="#E3AF80"></stop>
        </radialGradient>
        <linearGradient
          id="paint41_linear_18_101"
          x1="335.803"
          x2="334.837"
          y1="102.604"
          y2="111.87"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0B191"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint42_linear_18_101"
          x1="335.174"
          x2="335.899"
          y1="104.071"
          y2="115.773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAA88"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint43_linear_18_101"
          x1="332.284"
          x2="335.892"
          y1="120.893"
          y2="110.815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9A46C"></stop>
          <stop offset="1" stopColor="#E1CEC0" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint44_linear_18_101"
          x1="308.865"
          x2="336.702"
          y1="109.65"
          y2="114.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E603B"></stop>
          <stop offset="0.349" stopColor="#6E603B"></stop>
          <stop offset="0.797" stopColor="#E5CEA5"></stop>
          <stop offset="1" stopColor="#E6D8BD"></stop>
        </linearGradient>
        <linearGradient
          id="paint45_linear_18_101"
          x1="310.092"
          x2="340.448"
          y1="109.978"
          y2="112.951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#584728"></stop>
          <stop offset="1" stopColor="#7A6642" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint46_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(38.83 44.469 484.765) scale(6.13477 24.9993)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.385" stopColor="#F8EDEB"></stop>
          <stop offset="1" stopColor="#E3AF80"></stop>
        </radialGradient>
        <linearGradient
          id="paint47_linear_18_101"
          x1="307.868"
          x2="312.976"
          y1="76.992"
          y2="81.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0B191"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint48_linear_18_101"
          x1="308.495"
          x2="315.688"
          y1="77.924"
          y2="81.966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAA88"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint49_linear_18_101"
          x1="317.334"
          x2="312.756"
          y1="86.059"
          y2="80.076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9A46C"></stop>
          <stop offset="1" stopColor="#E1CEC0" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint50_linear_18_101"
          x1="301.742"
          x2="315.057"
          y1="95.606"
          y2="80.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E603B"></stop>
          <stop offset="0.349" stopColor="#6E603B"></stop>
          <stop offset="0.797" stopColor="#E5CEA5"></stop>
          <stop offset="1" stopColor="#E6D8BD"></stop>
        </linearGradient>
        <linearGradient
          id="paint51_linear_18_101"
          x1="302.405"
          x2="315.758"
          y1="95.006"
          y2="78.199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#584728"></stop>
          <stop offset="1" stopColor="#7A6642" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint52_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(77.001 130.953 272.385) scale(13.6502 55.6249)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.385" stopColor="#F8EDEB"></stop>
          <stop offset="1" stopColor="#E3AF80"></stop>
        </radialGradient>
        <linearGradient
          id="paint53_linear_18_101"
          x1="359.661"
          x2="362.943"
          y1="71.45"
          y2="85.665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0B191"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint54_linear_18_101"
          x1="359.476"
          x2="366.5"
          y1="73.943"
          y2="90.905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAA88"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint55_linear_18_101"
          x1="363.751"
          x2="363.969"
          y1="100.326"
          y2="83.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9A46C"></stop>
          <stop offset="1" stopColor="#E1CEC0" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint56_linear_18_101"
          x1="323.349"
          x2="366.882"
          y1="95.587"
          y2="88.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E603B"></stop>
          <stop offset="0.349" stopColor="#6E603B"></stop>
          <stop offset="0.797" stopColor="#E5CEA5"></stop>
          <stop offset="1" stopColor="#E6D8BD"></stop>
        </linearGradient>
        <linearGradient
          id="paint57_linear_18_101"
          x1="325.332"
          x2="371.803"
          y1="95.448"
          y2="84.411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#584728"></stop>
          <stop offset="1" stopColor="#7A6642" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint58_linear_18_101"
          x1="240.615"
          x2="302.115"
          y1="160.992"
          y2="147.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8D5518"></stop>
          <stop offset="0.254" stopColor="#BC8040"></stop>
          <stop offset="1" stopColor="#F7C6BB"></stop>
        </linearGradient>
        <radialGradient
          id="paint59_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(10.784 -716.755 1380.316) scale(64.1327 103.195)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.337" stopColor="#B78C5B"></stop>
          <stop offset="0.666" stopColor="#F5D2B6" stopOpacity="0.688"></stop>
          <stop offset="1" stopColor="#FBD6CE" stopOpacity="0.33"></stop>
        </radialGradient>
        <linearGradient
          id="paint60_linear_18_101"
          x1="247.115"
          x2="295.615"
          y1="156.492"
          y2="154.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#EDB494" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint61_linear_18_101"
          x1="241.615"
          x2="286.115"
          y1="157.992"
          y2="195.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.125" stopColor="#895621"></stop>
          <stop offset="0.844" stopColor="#F4C8B1"></stop>
          <stop offset="1" stopColor="#EBC8A2"></stop>
        </linearGradient>
        <linearGradient
          id="paint62_linear_18_101"
          x1="241.615"
          x2="284.615"
          y1="157.992"
          y2="191.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A95D50"></stop>
          <stop offset="1" stopColor="#A95D50" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint63_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-32.372 391.24 -337.708) scale(66.3042 30.4074)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.276" stopColor="#BC7C35"></stop>
          <stop offset="0.63" stopColor="#FCEDDC" stopOpacity="0.79"></stop>
          <stop offset="1" stopColor="#F4C3B6"></stop>
        </radialGradient>
        <linearGradient
          id="paint64_linear_18_101"
          x1="241.615"
          x2="294.115"
          y1="156.992"
          y2="132.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint65_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(22.99998 -9.49997 5.36956 13.00002 243.115 157.992)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.078" stopColor="#82511F"></stop>
          <stop offset="0.286" stopColor="#BA7B3A"></stop>
          <stop offset="1" stopColor="#F8DCC7"></stop>
        </radialGradient>
        <radialGradient
          id="paint66_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(4.99999 -40.00001 17.81149 2.22643 245.615 151.992)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.378" stopColor="#B77632"></stop>
          <stop offset="0.74" stopColor="#FDD6B7"></stop>
          <stop offset="1" stopColor="#F1DAD5"></stop>
        </radialGradient>
        <linearGradient
          id="paint67_linear_18_101"
          x1="242.115"
          x2="233.615"
          y1="155.492"
          y2="119.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#734C12"></stop>
          <stop offset="1" stopColor="#D1914D" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint68_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-62.622 249.032 -120.61) scale(63.0635 18.0181)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05" stopColor="#B17A39"></stop>
          <stop offset="0.749" stopColor="#F6DCC9"></stop>
          <stop offset="1" stopColor="#F2C1B2"></stop>
        </radialGradient>
        <radialGradient
          id="paint69_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-57.172 264.865 -143.212) scale(36.8917 21.2625)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.13" stopColor="#C59B6C"></stop>
          <stop offset="0.422" stopColor="#FCDAB1"></stop>
          <stop offset="1" stopColor="#F9CDBF"></stop>
        </radialGradient>
        <linearGradient
          id="paint70_linear_18_101"
          x1="241.615"
          x2="279.115"
          y1="156.992"
          y2="141.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A95D50"></stop>
          <stop offset="1" stopColor="#A95D50" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint71_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-139.865 160.497 24.957) scale(33.3542 33.7375)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.063" stopColor="#895621"></stop>
          <stop offset="0.406" stopColor="#B47832"></stop>
          <stop offset="0.828" stopColor="#F5C49D" stopOpacity="0.52"></stop>
          <stop offset="1" stopColor="#FCEBDD" stopOpacity="0.84"></stop>
        </radialGradient>
        <linearGradient
          id="paint72_linear_18_101"
          x1="241.615"
          x2="264.615"
          y1="155.992"
          y2="116.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#995F22"></stop>
          <stop offset="0.25" stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint73_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(19.50004 40.5 -11.80825 5.68547 248.615 154.992)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.177" stopColor="#B77632"></stop>
          <stop offset="0.807" stopColor="#E6CBB6" stopOpacity="0.85"></stop>
          <stop offset="1" stopColor="#F1DFD5"></stop>
        </radialGradient>
        <linearGradient
          id="paint74_linear_18_101"
          x1="240.615"
          x2="260.115"
          y1="161.492"
          y2="182.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B77632"></stop>
          <stop offset="1" stopColor="#B77632" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint75_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(5.45253 18.2961 -74.55708 22.2192 180.537 336.073)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.385" stopColor="#F8EDEB"></stop>
          <stop offset="1" stopColor="#E3AF80"></stop>
        </radialGradient>
        <linearGradient
          id="paint76_linear_18_101"
          x1="169.369"
          x2="175.196"
          y1="319.86"
          y2="339.413"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0B191"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint77_linear_18_101"
          x1="169.328"
          x2="180.622"
          y1="323.355"
          y2="346.416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAA88"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint78_linear_18_101"
          x1="177.61"
          x2="176.445"
          y1="359.808"
          y2="336.393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9A46C"></stop>
          <stop offset="1" stopColor="#E1CEC0" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint79_linear_18_101"
          x1="120.799"
          x2="180.913"
          y1="356.737"
          y2="342.531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E603B"></stop>
          <stop offset="0.349" stopColor="#6E603B"></stop>
          <stop offset="0.797" stopColor="#E5CEA5"></stop>
          <stop offset="1" stopColor="#E6D8BD"></stop>
        </linearGradient>
        <linearGradient
          id="paint80_linear_18_101"
          x1="123.556"
          x2="187.454"
          y1="356.369"
          y2="336.886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#584728"></stop>
          <stop offset="1" stopColor="#7A6642" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint81_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(8.92366 12.48828 -50.89015 36.36423 129.401 318.168)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.385" stopColor="#F8EDEB"></stop>
          <stop offset="1" stopColor="#E3AF80"></stop>
        </radialGradient>
        <linearGradient
          id="paint82_linear_18_101"
          x1="116.675"
          x2="126.211"
          y1="308.756"
          y2="322.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0B191"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint83_linear_18_101"
          x1="117.557"
          x2="132.166"
          y1="311.425"
          y2="326.011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAA88"></stop>
          <stop offset="1" stopColor="#E3C4A5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint84_linear_18_101"
          x1="133.373"
          x2="126.373"
          y1="336.98"
          y2="319.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9A46C"></stop>
          <stop offset="1" stopColor="#E1CEC0" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint85_linear_18_101"
          x1="89.373"
          x2="131.373"
          y1="349.48"
          y2="322.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E603B"></stop>
          <stop offset="0.349" stopColor="#6E603B"></stop>
          <stop offset="0.797" stopColor="#E5CEA5"></stop>
          <stop offset="1" stopColor="#E6D8BD"></stop>
        </linearGradient>
        <linearGradient
          id="paint86_linear_18_101"
          x1="91.373"
          x2="134.873"
          y1="348.48"
          y2="316.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#584728"></stop>
          <stop offset="1" stopColor="#7A6642" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint87_diamond_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(136.548 23.37 148.366) scale(117.776 95.3836)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9F6E4A"></stop>
          <stop offset="0.144" stopColor="#D6A785"></stop>
          <stop offset="0.432" stopColor="#F7EAE2"></stop>
          <stop offset="0.573" stopColor="#FFF7F4"></stop>
          <stop offset="1" stopColor="#EDC7B1"></stop>
        </radialGradient>
        <linearGradient
          id="paint88_linear_18_101"
          x1="142.373"
          x2="94.373"
          y1="234"
          y2="302.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#895621"></stop>
          <stop offset="0.682" stopColor="#F3C2B4"></stop>
          <stop offset="1" stopColor="#F3C2B4" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint89_diamond_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(65.41 -112.861 230.142) scale(97.3268 74.3302)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E603C"></stop>
          <stop offset="0.62" stopColor="#FEF5F0"></stop>
          <stop offset="1" stopColor="#F4DACC"></stop>
        </radialGradient>
        <linearGradient
          id="paint90_linear_18_101"
          x1="166.373"
          x2="166.185"
          y1="230.5"
          y2="327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#916441"></stop>
          <stop offset="1" stopColor="#F4C8BB" stopOpacity="0.51"></stop>
        </linearGradient>
        <radialGradient
          id="paint91_diamond_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-70.00022 -43.99964 56.00155 -89.0944 141.373 237)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8D5B38"></stop>
          <stop offset="0.353" stopColor="#DCAD8A"></stop>
          <stop offset="0.671" stopColor="#FFF8F5"></stop>
          <stop offset="1" stopColor="#F2C0B1"></stop>
        </radialGradient>
        <linearGradient
          id="paint92_linear_18_101"
          x1="144.373"
          x2="79.373"
          y1="237"
          y2="202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#926441"></stop>
          <stop offset="1" stopColor="#DEBBA4"></stop>
        </linearGradient>
        <radialGradient
          id="paint93_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-87.51 197.006 44.618) scale(103.598 51.5219)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C6C46"></stop>
          <stop offset="0.333" stopColor="#F4DDD0"></stop>
          <stop offset="0.521" stopColor="#FFF8F5"></stop>
          <stop offset="0.844" stopColor="#F4DDD0"></stop>
          <stop offset="1" stopColor="#EDC6B1"></stop>
        </radialGradient>
        <linearGradient
          id="paint94_linear_18_101"
          x1="143.873"
          x2="148.373"
          y1="243"
          y2="128.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#926440"></stop>
          <stop offset="0.708" stopColor="#F2C0B1" stopOpacity="0.67"></stop>
        </linearGradient>
        <radialGradient
          id="paint95_diamond_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(99.4997 14.99996 -17.50104 116.09024 149.873 238)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C6C46"></stop>
          <stop offset="0.333" stopColor="#F4DDD0"></stop>
          <stop offset="0.521" stopColor="#FFF8F5"></stop>
          <stop offset="0.844" stopColor="#F4DDD0"></stop>
          <stop offset="1" stopColor="#EDC6B1"></stop>
        </radialGradient>
        <linearGradient
          id="paint96_linear_18_101"
          x1="145.873"
          x2="259.373"
          y1="238"
          y2="211.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD7D59"></stop>
          <stop offset="1" stopColor="#AD7D59" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint97_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 55 -59.2308 0 140.373 238)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5B391D"></stop>
          <stop offset="0.12" stopColor="#8F613D"></stop>
          <stop offset="0.24" stopColor="#8F613D" stopOpacity="0.807"></stop>
          <stop offset="0.448" stopColor="#8F613D" stopOpacity="0"></stop>
          <stop offset="0.609" stopColor="#F5CBBE"></stop>
        </radialGradient>
        <radialGradient
          id="paint98_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 27.5 -29.6154 0 142.373 237)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5B391D"></stop>
          <stop offset="0.12" stopColor="#8F613D"></stop>
          <stop offset="0.24" stopColor="#8F613D" stopOpacity="0.807"></stop>
          <stop offset="0.458" stopColor="#8F613D" stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint99_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 150.373 238)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint100_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 149.373 235)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint101_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 145.373 232)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint102_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 140.373 232)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint103_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 138.373 235)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint104_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 142.373 237)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint105_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 145.373 235)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint106_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 145.373 240)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint107_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 141.373 240)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint108_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 145.373 243)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint109_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 149.373 243)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint110_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 148.373 240)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint111_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 141.373 238)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint112_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 137.373 237)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint113_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 134.373 233)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint114_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 141.373 234)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint115_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 144.373 234)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint116_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 148.373 238)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint117_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 141.373 242)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
        <radialGradient
          id="paint118_radial_18_101"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 2 -2 0 139.373 242)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.401" stopColor="#684225"></stop>
          <stop offset="1" stopColor="#AA6B3C" stopOpacity="0.37"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}
