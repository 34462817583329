import { useContext, useEffect, useRef, useState } from 'react';
import api from '../../../api/axiosConfigRoute';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import './login.css';
import { AuthContext } from '../../context/AuthContext';
import { Head } from '../../../layout/Head';

const LOGIN_URL = '/login';

export const Login = () => {

  const { login, /* logout */ } = useContext(AuthContext);
  const navigate = useNavigate();

  const userRef = useRef();
  const errRef = useRef();

  const [alias, setAlias] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    // userRef.current.focus();
  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await api.post(LOGIN_URL, JSON.stringify({ alias: alias, contrasena: password }),
        {
          headers: { 'Content-Type': 'application/json' },
          // withCredentials: true
        }
      );
      // console.log(response.data);
      // console.log(JSON.stringify(response?.data))
      const accessToken = await response?.data?.token;
      const id = await response?.data?.id;
      const nivel = await response?.data?.nivel
      const cod_empresa = await response?.data?.cod_empresa
      const nombre = await response?.data?.nombre
      const confirmacion = await response?.data?.confirmacion
      const no_invitados = await response?.data?.no_invitados
      setAlias('');
      setPassword('');


      // console.log(nombre, alias, accessToken, nivel)
      if (accessToken && nivel === 1) {
      login(id, alias, accessToken, cod_empresa, nivel, nombre, confirmacion, no_invitados);
        navigate(`/dashboard/cod/${cod_empresa}`, { replace: true })
      } else if (accessToken && nivel === 2) {
      login(id, alias, accessToken, cod_empresa, nivel, nombre, confirmacion, no_invitados);
        navigate(`/newInvitacion/invitacion-2/${id}`, { replace: true })
      }else{
        setErrMsg('Contraseña incorrecta');
      }

      // console.log(errMsg)
      // console.log(accessToken)

    } catch (err) {
      if (!err.response) {
        setErrMsg('No hay respuesta del servidor')
      } else if (err.response?.status === 400) {
        setErrMsg('No se encontro usuario o contrasena');
      } else if (err.response?.status === 401) {
        setErrMsg('No authorizado');
      } else {
        setErrMsg('Usuario o contraseña incorrectos');
      }
      errRef.current.focus();
    }
  }
  // useEffect(() => {
  //   setErrMsg('');
  // }, [alias, password])

  return (
    <>
      <Head title='Login' />
      {/* <Navbar home={false} /> */}
      <section className='loginContainer'>
        <Link to='/'>
          <BsArrowLeftCircle className='goBackHome' />
        </Link>
        <div className='loginWrapper'>
          <h1>
            Iniciar sesión
          </h1>
          <form onSubmit={handleSubmit} className="loginFormulario">
            {/* <label htmlFor='USERNAME'>Usuario</label> */}
            <input type="text"
              name="USERNAME"
              id="USERNAME"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setAlias(e.target.value)}
              value={alias}
              required
              placeholder="Usuario"
            />
            {/* <label htmlFor='PASSWORD'>Contraseña</label> */}
            <input type="password"
              name="PASSWORD"
              id="PASSWORD"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              placeholder="Contraseña"
            />
            {/* <Link to='/dashboard' className='loginLink'> */}
            <button className="loginContactoBoton">
              Entrar
            </button>
            {/* </Link> */}
          </form>
          <p ref={errRef} className={errMsg ? "errMsg" : "offScreen"} aria-live="assertive">
            {errMsg}
          </p>
        </div>
      </section>
    </>
  )
};
