import { useEffect, useState } from 'react';
import { Sidebar } from '../../components'
import { useNavigate, useParams } from "react-router-dom";
import api from '../../../api/axiosConfigRoute';
import '../addUsers/addSsers.css';
import { Head } from '../../../layout/Head';

export const UpdateUsers = () => {

  const [nombre, setNombre] = useState([])
  const [alias, setAlias] = useState([])
  const [contrasena, setContrasena] = useState([])

  const navigate = useNavigate();

  const { ID } = useParams();

  // GET USERS BY ID
  useEffect(() => {
    try {
      const response = api.get(`/users/${ID}`)
        .then((response) => {
          setNombre(response.data.NOMBRE)
          setAlias(response.data.ALIAS)
          setContrasena(response.data.CONTRASENA)
        })
      // setUsers(response.data)
      // console.log(data)
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  }, [])


  const data = {
    nombre: nombre,
    alias: alias,
    contrasena: contrasena,
  }

  // UPDATE USERS BY ID
  const updateUsers = (e) => {
    e.preventDefault();

    try {
      const response = api.put(`/users/${ID}`, data)
        .then(setTimeout(() => {
          navigate('/dashboard')
        }, 1000))
      // setUsers(response.data)
      console.log(data)
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        console.log(`Error: ${err.message}`);
      }

    }
  };

  return (
    <main className='homeDashboardContainer'>
      <Head title='Editar Usuario' />
      <Sidebar />
      <section className="addUsersSection">
        <div className="addUsers">
          <h2 className='allUsersTitle'>Editar usuario</h2>
          <form onSubmit={updateUsers} className='addUsersForm'>
            <input
              type="text"
              placeholder="Nombre Completo"
              className="formUsers"
              name='nombre'
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
              autoComplete="off"
            />
            <input
              type="text"
              placeholder="Nombre de usuario"
              className="formUsers"
              name='alias'
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              required
              autoComplete="off"
            />
            <input
              type="password"
              placeholder="Contraseña"
              className="formUsers"
              name='contrasena'
              value={contrasena}
              onChange={(e) => setContrasena(e.target.value)}
              required
            />
            <button
              className='formUsersBtn'
              type="submit">
              Agregar
            </button>
          </form>
        </div>
      </section>
    </main>
  )
};
