import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../auth/context';
import { Head } from '../../../layout/Head';
import { AllUsers, Sidebar } from '../../components'
import './homeDashboard.css';
export const HomeDashboard = () => {
  const { userInfo } = useContext(AuthContext);
  const { nivel, id } = userInfo;
  return (
    <>
      {(nivel === 2)
        ?
        <Navigate to={`/newInvitacion/invitacion-2/${id}`} replace />
        :
        <main className={`${(nivel === 1) ? 'homeDashboardContainer' : 'blanco'} `}>
          <Head title='Dashboard' />
          <section className='homeDashboardWrapper'>
            <h1>Lista de Invitados</h1>
            <AllUsers />
          </section>
          <Sidebar />
        </main>
      }
    </>
  )
}
