import { useEffect, useRef, useState } from "react";
import { MdLocationOn } from "react-icons/md";
import './intro.css';

export const FechaYHora = () => {
  const refSubParr = useRef(null);

  const [parrIsVisible, setParrIsVisible] = useState();

  useEffect(() => {
    const { current } = refSubParr;
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setParrIsVisible(entry.isIntersecting);
      // console.log('entry', entry)
    })
    observer.observe(current)
    return () => {
      observer.disconnect(current); // *** Use the same element
    }
  }, [])

  return (
    <div ref={refSubParr} className={`fechaHora_container ${parrIsVisible ? 'show' : 'hidden'}`} >
      <div className="fechaBoda">
        <span className=" fechaBodaDia">
          17
        </span>
        <span className=" fechaHora_parr" >
          Diciembre
        </span>
        <span className=" fechaBodaAnio" >
          2022
        </span>
        <span className=" fechaHora_parr">
          Hora: 3.00 P.M
        </span>
      </div>

      <div className="parteDerecha">
        <p className="fechaHora_parr">
          Ceremonia religiosa,
        </p>
        <p className="fechaHora_parr">
          Boda civil  y recepción
        </p>
        <p className="fechaHora_parr">
          Hotel de campo mana del cielo
        </p>
        <a href="https://g.page/hotelcampomana?share" target="_blank" rel="noreferrer">
          <button className="btn btnUbicacion">
            <MdLocationOn style={{ margin: "0 5px" }} />
            Ubicación
          </button>
        </a>
      </div>

    </div>
  )
}
