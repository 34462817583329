import { useContext } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../auth/context"

export const PrivateRoute = ({ permisos }) => {

  const { logged } = useContext(AuthContext);
  const location = useLocation();

  if (logged) {
    return (
      <Outlet />
    )
  } else {
    return (
      <Navigate to="/" state={{ from: location }} replace />
    )
  }

  // return (logged)
  //   ? children
  //   : <Navigate to="/" />
}
