import { React } from 'react';
import NavegacionNew from './NavegacionNew';
import NavegacionMovilNew from './NavegacionMovil';
import { Link } from 'react-router-dom';
import './NavbarNew.css';

export const NavbarNew = (props) => {

  return (
    <header className="NavbarNew">
      <Link to="/invitaciones/landingboda-page-1" className="logoNew">
        <h1>Boda Valle Rivera</h1>
      </Link>
      <NavegacionNew />
      <NavegacionMovilNew />
      <div className="menu-barsNew" id="menu-barsNew">
      </div>
    </header>
  );
}
