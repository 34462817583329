import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../auth/context"

export const NotAuthBeforeLoginRoute = () => {

  const { logged, userInfo } = useContext(AuthContext);

  if (userInfo) {
    const { cod_empresa } = userInfo;

    return (!logged)
      ? <Outlet />
      : <Navigate to={`/dashboard/cod/${cod_empresa}`} />
  }else{
    return (!logged)
      ? <Outlet />
      : <Navigate to="/login" />
  }
}
