import { SvgDesarrollo, SvgDisenoWeb, SvgDisenoGrafico } from '../../media/svg/Icons';
import { cards } from '../../assets/cards'
import './sections.css'
import { useEffect, useRef, useState } from 'react';

const icons = [
  <SvgDesarrollo className='sectionOneIcon' />,
  <SvgDisenoWeb className='sectionOneIcon' />,
  <SvgDisenoGrafico className='sectionOneIcon' />,
]

export const SectionOne = () => {
  const refCard = useRef(null);

  const [cardIsVisible, setCardIsVisible] = useState();

  useEffect(() => {
    const {current} = refCard;
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setCardIsVisible(entry.isIntersecting);
      // console.log('entry', entry)
    })
    observer.observe(current)
    return () => {
      observer.disconnect(current); // *** Use the same element
    }
  }, [])

  return (
    <section id='Servicios' className='sectionOneContainer'>
      <div className='sectionOneCricle1'>
      </div>
      <div className='sectionOneCricle2'>
      </div>
      <div className='sectionOneWrapper'>
          <h2 className='sectionOneh2'>
            Ofrecemos:
          </h2>
        <div className='sectionOneCardsContainer'ref={refCard}>
          {cards.slice(0).map((card, index) => (
            <div key={card.id}  className={`sectionOneCard ${cardIsVisible ? 'showArc' :'hidenArc'}`} >
              <span className='sectionOneCardTriangle'>
              </span>
              {/* <img src={card} alt={card} className='sectionOneCardIcon' /> */}
              <h3 className='sectionsOneCardText'>
                {/* <SvgDesarrollo className='sectionOneIcon' /> */}
                {icons[index]}
                {card.text}
              </h3>
              <div className={card.css}>
                <p className='sectionOneText'>
                  {card.tooltip}
                  <br />
                  {card.tooltip2}
                </p>
              </div>
            </div>
          ))}
        </div>
        {/*
        <div className='sectionOneTooltip'>
          <p className='sectionOneText'>
            ¿Dominios web?  ¿Correos corporativos? ¿SEO? ¿Apps? 🧐
            <br />
            ¡Nombralo y lo haremos posible!  🤓
          </p>
        </div>
*/}
      </div>
    </section>
  )
};
