import { ArcadiusApp } from "./ArcadiusApp";

const App = () => {

  return (
    <div >
      <ArcadiusApp />
    </div>
  );
}

export default App;
