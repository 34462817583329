import { Routes, Route } from "react-router-dom";
import { Home } from "../website/pages";
import { Login } from "../auth/pages/login/Login";
import { PrivateRoute } from "./PrivateRoute";
import { InvitacionesRoutes } from "../invitacionesBodas/routes/InvitacionesRoutes";
import { InvitacionRoutes02 } from "../invitaciones/02-invitacion/router/InvitacionRoutes02";
import { HomeDashboard } from "../dashboard/pages";
import { AddUsers, UpdateUsers, UserById } from "../dashboard/components";
import { NotAuthBeforeLoginRoute } from "./NotAuthBeforeLoginRoute";

export const AppRouter = () => {
  return (
    <>
      <Routes>
        {/* RUTAS PUBLICAS */}
        <Route path='/invitaciones/*' element={<InvitacionesRoutes />} />
        <Route path='/newinvitacion/*' element={<InvitacionRoutes02 />} />

        {/* RUTAS OCULTAS LUEGO DEL LOGIN */}
        <Route element={<NotAuthBeforeLoginRoute />} >
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
        </Route>

        {/* RUTAS PRIVADAS */}
        <Route element={<PrivateRoute /* nivelPermisos={1} */ />} >
          {/* RUTAS DASHBOARD  */}
          <Route path='/dashboard/cod/:COD_EMPRESA' element={<HomeDashboard />} />
          <Route path='/dashboard/users/:ID' element={<UserById />} />
          <Route path='/dashboard/users-new' element={<AddUsers />} />
          <Route path='/dashboard/users-edit/:ID' element={<UpdateUsers />} />
          {/* RUTAS INVITACION 02  */}
        </Route>

        <Route path="*" element={<Home />} />

      </Routes>
    </>
  )
};
