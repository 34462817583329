import { /* useContext, */ useState } from 'react';
import { Sidebar } from '../../components'
// import { useForm } from '../../../hooks/useForm';
import { useNavigate } from "react-router-dom";
import api from '../../../api/axiosConfigRoute';
import './addSsers.css'
// import { AuthContext } from '../../../auth/context/AuthContext';

export const AddUsers = () => {

  // const { userInfo } = useContext(AuthContext);

  const [nombre, setNombre] = useState([])
  const [alias, setAlias] = useState([])
  const [contrasena, setContrasena] = useState([])
  const [reset, setReset] = useState([])

  const navigate = useNavigate();

  const data = {
    nombre: nombre,
    alias: alias,
    contrasena: contrasena,
    // token: userInfo?.accessToken
  }

  // const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
  // const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,32}$/;

  const handleSubmitUsers = async (e) => {
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response = await api.post('/users', data)
        .then(setTimeout(() => {
          navigate('/dashboard')
        }, 1000))
      reset();
      // console.log(data)
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        console.log(`Error: ${err.message}`);
      }

    }
  };
  return (
    <main className='homeDashboardContainer'>
      <Sidebar />
      <section className="addUsersSection">
        <div className="addUsers">
          <h2 className='allUsersTitle'>Agregar usuario</h2>
          <form onSubmit={handleSubmitUsers} className='addUsersForm'>
            <input
              type="text"
              placeholder="Nombre Completo"
              className="formUsers"
              name='nombre'
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
              autoComplete="off"
            />
            <input
              type="text"
              placeholder="Nombre de usuario"
              className="formUsers"
              name='alias'
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              required
              autoComplete="off"
            />
            <input
              type="password"
              placeholder="Contraseña"
              className="formUsers"
              name='contrasena'
              value={contrasena}
              onChange={(e) => setContrasena(e.target.value)}
              required
            />
            <input
              type="radio"
              className="formUsers"
              id="nivel1"
              name="nivel"
              value="nivel1" />
            <label for="nivel1">
              Nivel 1
            </label>
            <input
              type="radio"
              className="formUsers"
              id="nivel2"
              name="nivel"
              value="nivel2" />
            <label for="nivel2">
              Nivel 2
            </label>
        <button
          className='formUsersBtn'
          onClick={(e) => setReset(data)}
          type="submit">
          Agregar
        </button>
      </form>
    </div>
          </section >
        </main >
        )
};
