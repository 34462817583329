import { Route, Routes } from "react-router-dom"
import { PrivateRoute } from "../../../router/PrivateRoute";
import { Home } from "../pages/Home";

export const InvitacionRoutes02 = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute /* nivelPermisos={2} */ />} >
        <Route path='invitacion-2/:ID' element={<Home />} />
      </Route>
    </Routes>
  )
};
