import React from "react";
import { motion } from "framer-motion";

export function RamitoDorado() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="213"
      height="290"
      fill="none"
      viewBox="0 0 213 290"
      className="ramitoDorado"
    >
      <motion.path
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: [0.5, 1, 0.5],
          // rotate:[0,20,0]
        }}
        transition={{
          loop: Infinity,
          duration: 10,
          ease: "easeInOut",
        }}
        id="ramito_dorado"
        stroke="url(#paint0_linear_25_52)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M84.678 85.5c-3.834-2-14.4-5.6-20-4-7 2-17.275-12.938-23.5-24.5-3.5-6.5 7-24 15-19 3-10 16-5 28 9.5 7.873 9.514-3 34.5.5 38zm0 0C82.51 79 77.178 68 71.178 66s-10.834-15.833-12.5-22.5m80.5 245.5c-8.834-15.333-39.1-79.2-35.5-96-.5-14.5-20-24-13.5-58 2-6-3.5-47.5-6-51m47 189.5c1-9.5 9.5-69.5 38.822-77.5m-66.822-.5c-4.667-17.667-21.6-52.8-56-60m-9.5-42c16.5 14.667 49.7 46.7 50.5 57.5m16 41c-.5-9.5.6-30.7 9-39.5 10.5-11 8-19 11-20.5s24.5-24 24.5-27.5m-125 61c19.166 6.333 59 23.3 65 40.5 4 12.5 29.5 42 31.5 43.5M90.678 126c3-7.5 4-20 5.5-25 4-18.5 23.5-37 26-44m-32 65c-1.5-20.333-1.9-63.1 4.5-73.5 8-13 7.5-33 12-35.5m36 140.5c-12.834 9.667-38.7 31.7-39.5 42.5m7.5-40.5c5.508-2.883 22.5-1 30-3 10.111-2.696 24.558-19.914 33.504-36.581 5.03-9.369-10.059-34.594-21.555-27.388-4.311-14.414-22.992-7.207-40.237 13.694-11.314 13.714 3.317 48.23-1.712 53.275zM89.71 207.44c-7.12-.515-17.631 2.56-28.033 6.56-11.221 4.315-31.487 5.427-48.5-8-9.564-7.548-19.5-31.5-5-43 3.5-31.5 36.31-27.588 64-13.5 18.169 9.243 9.935 54.935 17.533 57.94zM133.254 260c6.37-4.98 33.456-9.056 45.924-12.5 13.45-3.715 24.312-18.446 31.419-40.18 3.994-12.217-2.574-37.899-23.896-37.874-24.257-23.153-49.791-4.558-66.523 24.554-11 34 18.24 58.706 13.076 66zM93.178 116c7.12-.515 14-12 31-12 7.648 0 20-15.5 23-25 3.669-11.618 4-20.5-10.5-32-14.5-12.5-49.56 4.061-46.734 35 1.234 13.5 6.734 17 3.234 34zm-13.806 38.725c-3.834-2-13.593 5.675-19.193 7.275-7 2-19.776-3.438-26.001-15-3.5-6.5.5-14.5 2.5-19s25-17.5 37-3c7.873 9.514 2.194 26.225 5.694 29.725zM58.01 111.974c-2.504-.992-9.278-2.535-12.635-1.17-4.197 1.707-12.08-1.519-16.698-8.304-2.596-3.814 1.221-15.084 6.5-12.5 1.192-6.421 10.305-9.88 18-1 6.5 7.5-2 14 4.833 22.974zM95.678 47c0-22-3.5-31.5 19-45.5 2 4-1.5 15.5-2.5 19.5-.835 3.338-10.167 18.5-16.5 26zm14.5 136c12.725-25.183 16.5-29.5 48.244-41.382.178 5.838-10.835 16.797-14.395 20.746-2.972 3.296-21.618 16.04-33.849 20.636z"
      ></motion.path>
      <defs>
        <linearGradient
          id="paint0_linear_25_52"
          x1="56.5"
          x2="173.5"
          y1="192"
          y2="60.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE8625"></stop>
          <stop offset="0.51" stopColor="#F7EF8A"></stop>
          <stop offset="0.797" stopColor="#D2AC47"></stop>
          <stop offset="1" stopColor="#EDC967"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
