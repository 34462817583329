import { useEffect, useRef, useState } from 'react';
import {CardsProyectos, MovingTitle} from './'
// Info de los assets
import { cardsProjects, movingTitle } from '../../assets/cards'
import './sectionTwo.css';

export const SectionTwo = () => {
  const refCardProyects = useRef(null);
  const [cardProyectIsVisible, setCardProyectIsVisible] = useState();

  useEffect(() => {
    const { current } = refCardProyects;
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setCardProyectIsVisible(entry.isIntersecting);
      // console.log('entry', entry)
    })
    observer.observe(current)
    return () => {
      observer.disconnect(current); // *** Use the same element
    }
  }, [])

  return (
    <section id='Proyectos' className='Section2Container'>
      <div className='section2Cricle3'>
      </div>
      <div className='section2Cricle4'>
      </div>
      <MovingTitle movingTitle={movingTitle} />
      <div className='Section2Wrapper' ref={refCardProyects}>
        {cardsProjects.slice(0).map(item => (
          <CardsProyectos key={item.id} item={item} referencia={cardProyectIsVisible} />
        ))}
      </div>
    </section>
  )
};
