import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../auth/context';
import novios from '../media/images/bg.jpeg';
import rolon from '../media/music/rolon.mp3'
import './intro.css';
export const Outro = () => {
  const refParr = useRef(null);

  const [parrIsVisible, setParrIsVisible] = useState();

  useEffect(() => {
    const { current } = refParr;
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setParrIsVisible(entry.isIntersecting);
      // console.log('entry', entry)
    })
    observer.observe(current)
    return () => {
      observer.disconnect(current); // *** Use the same element
    }
  }, [])

  const { userInfo, logout } = useContext(AuthContext);
  const { nivel } = userInfo;

  const navigate = useNavigate()
  const handleLogOut = () => {
    logout();
    navigate(`/login`, { replace: true })
  }

  return (
    <section className='sectionOutro' id='outro' ref={refParr}>
      <div className={`outroTexts ${parrIsVisible ? 'show' : 'hidden'}`} >
        <p className={`parragraph ${parrIsVisible ? 'show' : 'hidden'}`} >
          Hay momentos en la vida que son especiales por si solos, pero sin lugar a dudas,
        </p>
        <p className={`parragraph ${parrIsVisible ? 'show' : 'hidden'}`} >
          el hecho de que Dios nos haya permitido compartirlo con ustedes, lo hace un día inolvidable
        </p>
        <p className={`parragraph ${parrIsVisible ? 'show' : 'hidden'}`} >
          ¡Te esperamos en nuestra boda!
        </p>
        <h2 className={`titleOutro ${parrIsVisible ? 'titleOutroshow' : 'titleOutrohidden'}`} >
          ¡No faltes!
        </h2>
      </div>
      <div className={`outroIMG ${parrIsVisible ? 'IMGshow' : 'IMGhidden'}`} >
        <img src={novios} alt='novios' className='endPicture' />
      </div>
      {(nivel === 2) &&
        <button className="btn botonADashboard" onClick={handleLogOut} style={{ marginBottom: "20px", marginLeft: '24px', width: '10px' }} >
          Salir
        </button>
      }
      <audio autoplay autoPlay loop >
        <source  src={rolon} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </section>
  )
}
