import './footer.css'
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

export const Footer = () => {
  return (
    <footer className='footer'>
      <ul className='footerList'>
        <li className='footerItem'>
          <a href='https://api.whatsapp.com/send?phone=50588369845&text=Hola,%20que%20tal,%20deseo%20saber%20mas%20informaci%C3%B3n' target='_blank' rel="noreferrer">
            <FaWhatsapp />
            <div className='footerSubItem'>
              <span>Whatsapp</span>
              +505 8836 9845
            </div>
          </a>
        </li>
        <li className='footerItem'>
          <a href='mailto:hollmann.valle@arcadiusnic.com' target='_blank' rel="noreferrer">
            <AiOutlineMail />
            <div className='footerSubItem'>
              <span>Email</span>
              hollmann.valle@arcadiusnic.com
            </div>
          </a>
        </li>
      </ul>
      <p className='footerText'>
        Copyright © 2022 <span>Arcadius</span>
      </p>
    </footer>
  )
};
