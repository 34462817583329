import './intro.css';
export const PopUpConfirmacion = ({ handleAsistenciaFalse, handleAsistenciaTrue}) => {
  return (
    <div className="parteDerecha" style={{ display: "flex", justifyContent: "space-around", flexDirection: "column", alignItems: "center" }}>
      <p className="fechaHora_parr">
        Confirmar su asistencia antes del <br /><span className='diciembre'>8 de diciembre</span>
      </p>

      <button className="btn" onClick={() => handleAsistenciaTrue('ASISTIRA')} style={{ width: "200px", fontSize: "2.5em" , marginTop:"100px"}}>
        Asistiré
      </button>

      <button className="btn" onClick={() => handleAsistenciaFalse('NO ASISTIRA')} style={{ width: "200px", fontSize: "2.5em", marginTop:"100px" }}>
        No Asistiré
      </button>
    </div>
  )
}
