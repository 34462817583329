import { useReducer } from "react";
import { types } from "../types/types"
import { AuthContext } from "./AuthContext";
import { authReducer } from "./authReducer";

const initializer = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return {
    logged: !!userInfo,
    userInfo: userInfo,
  }
}

export const AuthProvider = ({ children }) => {

  const [authState, dispatch] = useReducer(authReducer, {}, initializer)

  // const [auth, setAuth] = useState({});
  const login = (id, alias, accessToken, cod_empresa, nivel, nombre, confirmacion, no_invitados) => {
    const userInfo = {
      id: id,
      alias: alias,
      accessToken: accessToken,
      cod_empresa: cod_empresa,
      nivel:nivel,
      nombre:nombre,
      confirmacion:confirmacion,
      no_invitados: no_invitados
    }

    const action = {
      type: types.login,
      payload: userInfo
    }

    localStorage.setItem('userInfo', JSON.stringify(userInfo))

    dispatch(action);
  }

  const logout = () => {

    localStorage.removeItem('userInfo');
    const action = {
      type: types.logout
    };
    dispatch(action)

  }

  return (
    <AuthContext.Provider value={{
      ...authState,
      // Metodos
      login: login,
      logout: logout
    }}>
      {children}
    </AuthContext.Provider>
  )

}

export default AuthContext;
