import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import api from '../../../api/axiosConfigRoute';
import { PopUpConfirmacion } from "./PopUpConfirmacion";
import traje from '../assets/svg/manTraje.svg';
import vestido from '../assets/svg/womanDress.svg';
import lluviaDeSobres from "../assets/svg/lluviaDeSobres.svg";
import { AuthContext } from "../../../auth/context";

export const Asistencia = () => {

  const refParr = useRef(null);

  const [parrIsVisible, setParrIsVisible] = useState();
  const [menuConfirm, toggleMenuConfirm] = useState(false);
  const [confirmacion, setConfirmacion] = useState('');

  // ANIMACION ON SCROLL
  useEffect(() => {
    const { current } = refParr;
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setParrIsVisible(entry.isIntersecting);
      // console.log('entry', entry)
    })
    observer.observe(current)
    return () => {
      observer.disconnect(current); // *** Use the same element
    }
  }, [])

  const { userInfo } = useContext(AuthContext);
  const { no_invitados, nombre } = userInfo;

  const { ID } = useParams();

  // GET DATA PREVIA DE CONFIRMACION DE INVITADOS BY ID
  // useEffect(() => {
  //   try {
  //     const response = api.get(`/invitados/${ID}`)
  //       .then((response) => {
  //         setConfirmacion(response?.data[0]?.CONFIRMACION)
  //       })
  //   } catch (err) {
  //     if (err.response) {
  //       console.log(err.response.data);
  //       console.log(err.response.status);
  //       console.log(err.response.headers);
  //     } else {
  //       console.log(`Error: ${err.message}`);
  //     }
  //   }
  // }, [])

  // UPDATE CONFIRMACION(ASISTENCIA) BY ID
  // useEffect(() => {
  //   try {
  //     const response = api.put(`/invitados/${ID}`, data)
  //     // .then( /* algo */)
  //     console.log(data)
  //   } catch (err) {
  //     if (err.response) {
  //       console.log(err.response.data);
  //       console.log(err.response.status);
  //       console.log(err.response.headers);
  //     } else {
  //       console.log(`Error: ${err.message}`);
  //     }
  //   }
  //   toggleMenuConfirm(false)
  // }, [confirmacion])


  const dataAsistir = {
    confirmacion: 'ASISTIRA'
  }
  const dataNOAsistir = {
    confirmacion: 'NO ASISTIRA'
  }
  // console.log(confirmacion)
  const handleAsistenciaTrue = () => {
    // eslint-disable-next-line
    const response = api.put(`/invitados/${ID}`, dataAsistir)
    setConfirmacion('ASISTIRA')
    toggleMenuConfirm(false)
  }
  const handleAsistenciaFalse = () => {
    // eslint-disable-next-line
    const response = api.put(`/invitados/${ID}`, dataNOAsistir)
    setConfirmacion('NO ASISTIRA')
    toggleMenuConfirm(false)
  }
  // console.log(dataAsistir)
  // console.log(dataNOAsistir)

  return (
    <div ref={refParr} className={`vestimenta_container ${parrIsVisible ? 'show' : 'hidden'}`} >
      {menuConfirm
        ?
        <PopUpConfirmacion handleAsistenciaFalse={handleAsistenciaFalse} handleAsistenciaTrue={handleAsistenciaTrue} />
        :
        <>
          <div className="fechaBoda">
            <p className="fechaHora_parr">
              Código de vestimenta:
              <span className="vestidos">
                <img src={traje} alt="traje" />
                <img src={vestido} alt="vestido" />
              </span>
              Formal
            </p>
            <p className="fechaHora_parr">
              Lluvia de sobres
              <br />
              <img className="lluviaDeSobres" src={lluviaDeSobres} alt="lluvia de sobres" />
            </p>
          </div>

          <div className="parteDerecha">
            <p className="fechaHora_parr">
              Confirmar su asistencia antes del 8 de diciembre
            </p>
            <p className="fechaHora_parr">
              Invitado:
            </p> 
            <p className="golden invitados fechaHora_parr">
              {nombre}
            </p> 
            <p className="fechaHora_parr">
              Válido para:
            </p> 
            <p className="golden invitados fechaHora_parr">
              {no_invitados} Personas
            </p> 
            {(confirmacion === 'ASISTIRA' || confirmacion === 'NO ASISTIRA')
              ?
              <button className="btn" disabled={true} style={{ width: "200px" }}>
                Respuesta Enviada
              </button>
              :
              <button onClick={() => toggleMenuConfirm(true)} className="btn">
                Confirmar Asistencia
              </button>
            }
          </div>
        </>
      }
    </div>
  )
}
