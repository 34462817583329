import { useEffect, useRef, useState } from 'react';
import { Rama } from '../assets/svg/Rama';
import { RamitoDorado } from '../assets/svg/RamitoDorado';
import './intro.css';
export const Intro = () => {
  const refParr = useRef(null);

  const [parrIsVisible, setParrIsVisible] = useState();

  useEffect(() => {
    const { current } = refParr;
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setParrIsVisible(entry.isIntersecting);
      // console.log('entry', entry)
    })
    observer.observe(current)
    return () => {
      observer.disconnect(current); // *** Use the same element
    }
  }, [])

  return (
    <section className='sectionInvitacion1' id='intro' ref={refParr}>
      <Rama />
      <div className='introWrap'>
        <h2 className={`introTitle title ${parrIsVisible ? 'show' : 'hidden'}`} >
          Más valen dos que uno...
        </h2>
        <p className={`introParr  ${parrIsVisible ? 'show' : 'hidden'}`} >
          porque obtienen mas fruto de su esfuerzo.
        </p>
        <p className={`introParr  ${parrIsVisible ? 'show' : 'hidden'}`} >
          Si caen, uno levanta al otro.
        </p>
        <p className={`introParr  ${parrIsVisible ? 'show' : 'hidden'}`} >
          ¡Ay del que cae y no tiene quien lo levante!
        </p>
        <p className={`introParr  ${parrIsVisible ? 'show' : 'hidden'}`} >
          Uno solo puede ser vencido, pero dos pueden resistir
        </p>
        <p className={`introParr  ${parrIsVisible ? 'show' : 'hidden'}`} >
          ¡La cuerda de  tres hilos  no se rompe fácilmente !
        </p>
        <p className={`introParr  ${parrIsVisible ? 'show' : 'hidden'}`} >
          Eclesiastés 19:12
        </p>
      </div>
      <RamitoDorado />
    </section>
  )
}
