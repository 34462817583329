import { types } from "../types/types";

// const initialState = {
//   logged: false,
//   alias: '',
//   id: '',
//   token: ''
// }

export const authReducer = (state = {}, action) => {

  switch (action.type) {
    case types.login:
      return {
        ...state,
        logged: true,
        userInfo: action.payload
      };

    case types.logout:
      return {
        logged: false,
        // name: action.payload
      };

    default:
      return state;
  }

}
