import './home.css'
import { Contact, Footer, Hero, Navbar, SectionOne, SectionTwo, /* Servicios  */} from '../../components/';
import { ScrollTopButton } from '../../assets/';
import { Head } from '../../../layout/Head';

export const Home = () => {
  return (
    <div className="landingContainer">
      <Head title='Arcadius' />
      <Navbar home={true} />
      <Hero />
      {/* <Servicios /> */}
      <SectionOne />
      <SectionTwo />
      <Contact />
      <ScrollTopButton />
      <Footer />
    </div>
  )
};
