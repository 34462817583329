import React from "react";
import PortadaBodas from './PortadaBodas';
//import arregloInferior from './../ImagesBodas/arreglo_compuesto_inferior_V2.png';
import floralWhite from '../ImagesBodas/INVITACION/ADORNOS/floral-white.png'
import '../cssBodas/indexCSSBodas.css'
// import Footer from "../components/Footer/Footer2";
// import NavbarNew from "../components/newNavbar/NavbarNew";
import { NavbarNew } from "../components";
import { Footer } from "../../website/components";
import { Head } from "../../layout/Head";

export const MainIndexBodas = () => {

  return (
    <>
      <Head title='Invitaciones' />
      <div className="mainIndexContainerBodas">
        <NavbarNew />
        <PortadaBodas />

        <img className="arregloInferior" src={floralWhite} alt="flores boda" />

      </div>
      <Footer />
    </>
  )
};
