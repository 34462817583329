export const MovingTitle = ({movingTitle}) => {
  return (
      <div className='movingTitleContainer'>
        {movingTitle.slice(0).map(item => (
          <p key={item.id} className='movingTitle'>
            {item.title}
          </p>
        ))}
      </div>
  )
}
