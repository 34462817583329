import { useEffect, useState } from "react";
import { Sidebar } from '../../components'
import { useParams } from "react-router-dom";
import api from '../../../api/axiosConfigRoute';
import './userById.css';
import { Head } from "../../../layout/Head";

export const UserById = () => {

  const [user, setUser] = useState([]);

  useEffect(() => {
    api.get(`/users/${ID}`)
      .then((response) => {
        setUser(response.data)
      });
  }, [])

  const { ID } = useParams();


  return (
    <main className='homeDashboardContainer'>
      <Head title={`${user?.NOMBRE === undefined ? 'Dashboard' : user?.NOMBRE}`} />
      <Sidebar />
      <section className="userByIdContainer">
        <div className="userByIdWrapper">
          <h2>Usuario</h2>
          <p>Nombre: {user.NOMBRE}</p>
          <p>Alias: {user.ALIAS}</p>
        </div>
      </section>
    </main>
  )
};
