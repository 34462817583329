import React from "react";
import './cssBodas/indexCSSBodas.css';
import img01 from './ImagesBodas/fotos/webpimages/001-min.png';
import img02 from './ImagesBodas/fotos/webpimages/002-min.png';
import img03 from './ImagesBodas/fotos/webpimages/003-min.png';
import img04 from './ImagesBodas/fotos/webpimages/004-min.png';
import img05 from './ImagesBodas/fotos/webpimages/img05.png';
import img06 from './ImagesBodas/fotos/webpimages/006-min.png';

const FotosBodas = () =>{

	return(
		<div className="mainPage3Container">
			<div className="imagenesFotos">
				<div className="card">
					<img className="imgf01" src={img01} alt="" />
				</div>
				<div className="card">
					<img className="imgf02" src={img02} alt="" />
				</div>
				<div className="card">
					<img className="imgf03" src={img03} alt="" />
				</div>
				<div className="card">
					<img className="imgf04" src={img04} alt="" />
				</div>
				<div className="card">
					<img className="imgf05" src={img05} alt="" />
				</div>
				<div className="card">
					<img className="imgf06" src={img06} alt="" />
				</div>
			</div>
		</div>
	)
};

export default FotosBodas;
