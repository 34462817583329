import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../auth/context/AuthContext";
import './sidebar.css';


export const Sidebar = () => {

  const { userInfo, logout } = useContext(AuthContext);

  const navigate = useNavigate();
  const { id } = userInfo;

  // const handleUsuario = () => {
  //   navigate(`/dashboard/users/${id}`, { replace: true });
  // }

  const handleLogOut = () => {
    logout()
    navigate('/login', { replace: true });
  }

  return (
    <section className="sidebarContainer">
      {/* <h1 className='logo'>Arcadius</h1> */}
      <ul className='menuLista'>
        {/*
        <Link to={`/dashboard/${cod_empresa}`}>
          <li className='menuItem'>
            Dashboard
          </li>
        </Link>
*/}
        <Link to={`/newInvitacion/invitacion-2/${id}`} target="_blank" rel="noopener noreferrer">
          <li className='menuItem'>
            Ver Invitación
          </li>
        </Link>

        {/* <li onClick={handleUsuario} className='menuItem'> */}
        {/*   Perfil */}
        {/* </li> */}
        {/* <Link to={`/dashboard/users/${id}`}> */}
        {/* </Link> */}
        <li onClick={handleLogOut} className='menuItem'>
          Salir
        </li>
      </ul>
    </section>
  )
};
