export const CardsProyectos = ({ item, referencia }) => {
  return (
    <a href={item.url} className="linkCard" target="_blank" rel="noreferrer">
      <div key={item.id.toString()} className={`section2CardsContainer ${referencia ? 'showArc' : 'hidenLeftArc'}`} >
        <img src={item.img} alt={item.title} className='section2IMG' />
        <div className='section2CardTexts'>
          <h3 className='section2CardTitle'>
            {item.title}
          </h3>
          <p className='section2CardDesc'>
            {item.description}
          </p>
        </div>
        <div className='tagContainers' >
          <span className='tag1'>
            {item.tag1}
          </span>
          <span className='tag2'>
            {item.tag2}
          </span>
        </div>
      </div>
    </a>
  )
}
