import './navbar.css'
import logo from '../../media/images/arcadiusLogoBlanco.png'
import { Link as Enlace } from 'react-router-dom'
import { Link } from 'react-scroll';
import { useState } from 'react';

const links = [
  'Servicios',
  'Proyectos',
  // 'Nosotros',
  'Contáctanos',
]

function LinkItems({ estadoMiniMenu, cerrarMenu }) {

  const handle = () => {
    cerrarMenu()
  }

  return (
    <ul className={estadoMiniMenu ? 'navbarList' : 'navbarListNone'}>
      {links.map(item => (
        <li className='navbarItems' key={item} >
          <Link to={item} smooth={true} onClick={handle}>
            {item}
          </Link>
        </li>
      ))}
      <Enlace to='/login'>
        <li className='navbarItems'>
          Inicia sesión
        </li>
      </Enlace>
    </ul>
  )
}

export const Navbar = ({ home }) => {

  const [miniMenu, openMiniMenu] = useState(false)

  const handleMiniMenu = () => {
    openMiniMenu(!miniMenu);
  }

  const handleCerrarMiniMenu = () => {
    openMiniMenu(false)
  }

  return (
    <div className='navbarContainer'>
      <div className='navbarWrapper'>
        {home
          ?
          <>
            <Link to='Hero' smooth={true}>
              <img src={logo} alt='Arcadius logo' className='arcadiusLogo' />
            </Link>
            <LinkItems
              estadoMiniMenu={miniMenu}
              cerrarMenu={handleCerrarMiniMenu}
            />
            <button
              onClick={handleMiniMenu}
              className={miniMenu ? 'miniMenuEquis' : 'miniMenu'}>
              <div></div>
              <div></div>
              <div></div>
            </button>
          </>
          :
          <Enlace to='/'>
            <img src={logo} alt='Arcadius logo' className='arcadiusLogo' />
          </Enlace>
        }
      </div>
    </div>
  )
};
