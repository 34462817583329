import { Head } from "../../../layout/Head";
import { Intro, Hero, Outro } from "../components";
// import { Itinerario } from "../components/Itinerario";

export const Home = () => {
  return (
    <>
      <Head title='Yahosca y Carlos | Invitación' />
      <main className="mainInvitacion2">
        <Hero />
        {/* <Itinerario /> */}
        <Intro />
        <Outro />
      </main>
    </>
  )
}
