import { motion } from 'framer-motion';
import background from '../../media/video/background.mp4';
import poster from '../../media/video/bgImage.jpg';
import './hero.css'
import { Link } from 'react-scroll'

export const Hero = () => {
  return (
    <section id='Hero' className='heroContainer'>
      <div className='heroWrapper'>
        <video id='videoBg' poster={poster} autoPlay muted loop>
          <source src={background} type='video/mp4' />
        </video>
          <div className='heroText'>
        <motion.div initial="hidden" animate="visible" variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1,
            transition: {
              delay: 0.4
            }
          }
        }}>
            <h1 className='heroTexth1'>
              Nuestra especialidad es
              <br />
              <span className='heroTextEnfasis'>
                elevar tus ideas
              </span>
              <br />
              al siguiente nivel
              <br />
            </h1>
        </motion.div>
            <button className='heroBtn'>
              <Link to='Servicios' smooth={true}>
                ¡Vamos! 🚀
              </Link>
            </button>
          </div>
      </div>
    </section>
  )
};
