import { ScrollToTop } from "./assets"
import { AuthProvider } from "./auth/context"
import { AppRouter } from "./router/AppRouter"

export const ArcadiusApp = () => {
  return (
    <AuthProvider>
      <ScrollToTop>
        <AppRouter />
      </ScrollToTop>
    </AuthProvider>
  )
}
