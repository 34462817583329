import { useEffect, useState } from "react";
import './allUsers.css';
import api from '../../../api/axiosConfigRoute';
import {  useParams } from "react-router-dom";

export const AllUsers = () => {

  const [users, setUsers] = useState([])
  const { COD_EMPRESA } = useParams();

  const fetchInvitados = async () => {
    try {
      const response = await api.get(`/invitados/${COD_EMPRESA}`)
      setUsers(response.data)
      // console.log(response.data)

    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    fetchInvitados(); // eslint-disable-next-line
  }, [])

  return (
    <section className="allUsersContainer">
      <div className="headingAllUsers">
        {/* <h2 className="allUsersTitle">Invitados</h2> */}
        {/* <Link to="/dashboard/users-new"> */}
        {/*   <button className="formUsersBtn"> */}
        {/*     Agregar nuevo usuario */}
        {/*   </button> */}
        {/* </Link> */}
      </div>
      {/* {users.ID} */}
      <div className="allUsersTableHeader">
        <p>Nombre</p>
        <p>No. de invitados</p>
        <p>Confirmación</p>
      </div>
      {
        (!users.length > 0)
          ?
          <h2>cargando datos...</h2>
          :
          <>
            {users.slice(0).map((user) => (
              <div key={user.ID} className="allUsersCard">
                <p className="allUsersCardItem">{user.NOMBRE}</p>
                <p className="allUsersCardItem">{user.NO_INVITADOS}</p>
                <p className="allUsersCardItem">{user.CONFIRMACION}</p>
              </div>
            ))
            }
          </>
      }
    </section >
  )
};
