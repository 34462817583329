import React from "react";
import './../cssBodas/indexCSSBodas.css'
// import NavbarNew from "../newNavbar/NavbarNew";
import arregloInferior from './../ImagesBodas/arreglo_compuesto_inferior_V2.png';
//import floralWhite from '../../images/INVITACION/ADORNOS/floral-white.png'
//import floralGolden from '../../images/INVITACION/ADORNOS/floral-golden.png'
import FotosBodas from '../FotosBodas';
// import Footer from "../../Footer/Footer2";
import { NavbarNew } from "../components"
import { Footer } from "../../website/components";
import { Head } from "../../layout/Head";

export const Page3 = () => {

  return (
    <>
      <Head title='Invitaciones' />
      <div className="mainPage3Container">
        <NavbarNew />
        <FotosBodas />
        <img className="arregloInferiorp2" src={arregloInferior} alt="flores boda" />
      </div>
      <Footer />
    </>
  )
};
