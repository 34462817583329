import { Route, Routes } from "react-router-dom"
import { Page2, Page3 } from "../IndexBodas"
import { MainIndexBodas } from "../IndexBodas/MainIndexBodas"

export const InvitacionesRoutes = () => {
  return (
    <Routes>
      <Route path='landingboda-page-1' element={<MainIndexBodas />} />
      <Route path='landingboda-page-2' element={<Page2 />} />
      <Route path='landingboda-page-3' element={<Page3 />} />
    </Routes>
  )
};
