import React from "react";
import './../cssBodas/indexCSSBodas.css'
import arregloInferior from './../ImagesBodas/arreglo_compuesto_inferior_V2.png';
//import floralWhite from '../../images/INVITACION/ADORNOS/floral-white.png'
//import floralGolden from '../../images/INVITACION/ADORNOS/floral-golden.png'
import Horaylugar from './../Horaylugar';
import { NavbarNew } from "../components"
import { Footer } from "../../website/components";
import { Head } from "../../layout/Head";


export const Page2 = () => {

  return (
    <>
      <Head title='Invitaciones' />
      <div className="mainPage2Container">
        <NavbarNew />
        <Horaylugar />
        <img className="arregloInferiorp2" src={arregloInferior} alt="flores boda" />
      </div>
      <Footer />
    </>
  )
};
