import React from "react";
import '../cssBodas/indexCSSBodas.css'
//import pareja from './../ImagesBodas/mancha_rosa_pareja.png';
//import arregloSuperiorV2 from './../ImagesBodas/arreglo_compuesto_v2.png';
import floralWhite from '../ImagesBodas/INVITACION/ADORNOS/floral-white.png';
//import hr357px from './../ImagesBodas/hr_357px.png';

const Portada = () => {

  return (
    <div className="portadaBodas">
      <img className="arregloSuperior" src={floralWhite} alt="flores boda" />
      <div className="weaTextCont">
        <h1 className="weaTitulo">Hollmann y Maricela</h1>
        <div className="weaTextDesc">
          <p className="weaDesc">
            Septiembre, 03
          </p>
          <p className="weaDesc">
            ¡Vamos por el Si!
          </p>
        </div>
      </div>
      {/*
			
			<div className="infoContentBodas">
				<h1 className="infoContentTituloBodas">¡Vamos por el Si!</h1>
				<h2 className="bodaCursivaH2">Boda</h2>
				<h1 className="bodaCursivaH1">Valle Rivera</h1>
				<h3 className="bodaCursivaH3">en</h3>
				<p className="parrafo1">Matagalpa, Nicaragua</p>
				<img className="hr357px" src={hr357px} alt="" />
				<hr />
				
				<p className="parrafo2">20 de Agosto <br />2021</p>
			</div>
		*/}
      {/*<img className="imgPortadaBodas" src={pareja} alt="Novios Pareja" />*/}
    </div>
  );
}

export default Portada;
