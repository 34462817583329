import './cssBodas/indexCSSBodas.css'
import arregloSuperiorV2 from './ImagesBodas/arreglo_compuesto_v2.png';
//import floralWhite from '../images/INVITACION/ADORNOS/floral-white.png';
//import floralGolden from '../images/INVITACION/ADORNOS/floral-golden.png';
//import iglesia from "./ImagesBodas/parroquia-arreglo.png";
import hr500px from "./ImagesBodas/hr_500px.png";
// import jwt_decode from "jwt-decode";       // Usuarios
// import { getUser } from "../UserFunctions"; // Usuario
// import TimerMixin from "react-timer-mixin";
// import Swal from "sweetalert2";
// import axios from "axios";

  const Horaylugar = () =>{

  // constructor() {
  //   super();
  //   this.state = {
  //     ALIAS: "",
  //     COD_EMPRESA: "",
  //     COD_INVITADO: "",
  //     INVITADOS: [],
  //     ASISTIR: "ASISTIR",
  //     NO_ASISTIR: "NO ASISTIR",
  //     errors: {},
  //   };
  //   this.onChange = this.onChange.bind(this);
  //   this.onSubmit = this.onSubmit.bind(this);
  // }
  // onChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });

  // }

  // DATOS_INVITADO = () => {
  //   const url = `http://localhost:3333/invitado/datos/${this.state.COD_EMPRESA}/${this.state.COD_INVITADO}`;

  //   fetch(url)
  //     .then((respuesta) => respuesta.json())
  //     .then((resultado) => this.setState({ INVITADOS: resultado }));
  // };


  // componentDidMount() {
  //   var tiempo = '600'
  //   const token = localStorage.usertoken;
  //   const decoded = jwt_decode(token);
  //   getUser(decoded.uid).then((res) => {
  //     this.setState({
  //       ALIAS: res.data.USERNAME,
  //       COD_EMPRESA: res.data.COD_EMPRESA,
  //       COD_INVITADO: res.data.COD_INVITADO,
  //     });
  //     TimerMixin.setTimeout(() => {
  //       this.DATOS_INVITADO();
  //     }, tiempo);
  //   });
  // }

  // registro = (newSolicitud) => {

  //   return axios
  //     .post(`http://localhost:3333/invitado/confirmar`, {
  //       COD_INVITADO: newSolicitud.COD_INVITADO,
  //       COD_EMPRESA: newSolicitud.COD_EMPRESA,
  //       CONFIRMACION: newSolicitud.CONFIRMACION,

  //     })
  //     .then((response) => {
  //       Swal.fire(
  //         'Confrimacion',
  //         response.data.mensaje,
  //         response.data.alerta
  //         // 'Registrado',
  //         // 'SUCCESS'
  //       )

  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       window.alert("Verifique los Datos");
  //       window.location.reload();
  //     });
  // };


  const onSubmit = (e) => {

    e.preventDefault();

    // Swal.fire({
    //   title: 'Desea Asistir',
    //   showDenyButton: true,
    //   // showCancelButton: true,
    //   confirmButtonText: `Asistir`,
    //   denyButtonText: `No Asistir`,
    // }).then((result) => {
    //   if (result.isConfirmed) {

    //     const newSolicitud = {
    //       COD_INVITADO: this.state.COD_INVITADO,
    //       COD_EMPRESA: this.state.COD_EMPRESA,
    //       CONFIRMACION: this.state.ASISTIR,
    //     };
    //     this.registro(newSolicitud).then((res) => { });

    //   } else if (result.isDenied) {
    //     const newSolicitud = {
    //       COD_INVITADO: this.state.COD_INVITADO,
    //       COD_EMPRESA: this.state.COD_EMPRESA,
    //       CONFIRMACION: this.state.NO_ASISTIR,
    //     };
    //     this.registro(newSolicitud).then((res) => { });

    //   }
    // })
  }


    return (
      <div className="horaContainer">
        <img className="arregloSuperiorP2" src={arregloSuperiorV2} alt="flores boda" />
        <div className="horaWrap">
          {/*
          <img className="imgHora" src={iglesia} alt="Iglesia parroquia" />
            */}


          <div className="ceremoniaWrap">
            <div className="ceremoniIzq">
              <h1 className="ceremoniaTitulo">Ceremonia</h1>
              <h2 className="ceremoniaTexto">Sábado 3 de septiembre del 2022</h2>
              <h2 className="ceremoniaTexto">3 : 00 p m - 4 : 00 p m</h2>
            </div>

            <img className="separador" src={hr500px} alt="" />


            <div className="ceremoniaDer">
              <div className="ceremoniaTextoWrap">
                <h2 className="ceremoniaTextoDer">Parroquia</h2>
                <h2 className="ceremoniaTextoDer">Santa María de Guadalupe</h2>
                <h2 className="ceremoniaTextoDer">Matagalpa</h2>
              </div>
              <button className="ceremoniaLink">
                <a href="https://goo.gl/maps/kwtdRjXba6N8xUUYA" target="_blank" rel="noreferrer">
                  Ubicación
                </a>
              </button>
            </div>
          </div>

          <div className="recepcionWrap">
            <div className="recepIzq">
              <h1 className="recepTitulo">Recepción</h1>
              <h2 className="recepTexto">Sábado 3 de septiembre del 2022</h2>
              <h2 className="recepTexto">6 : 00 p m - 12 : 00 a m</h2>
            </div>

            <img className="separador" src={hr500px} alt="" />

            <div className="recepDer">
              <div className="recepcionTwextoWrap">
                <h2 className="recepTextoDer">Restaurante</h2>
                <h2 className="recepTextoDer">El Pullazo</h2>
                <h2 className="recepTextoDer">Matagalpa</h2>
              </div>

              <button className="ceremoniaLink">
                <a href="https://goo.gl/maps/FLgWstTri6SNdouw7" target="_blank" rel="noreferrer">
                  Ubicación
                </a>
              </button>
            </div>
          </div>

          <div className="invitacionWrap">
            {/* this.state.INVITADOS.map((item) => {
              return (
                <span><b>Invitado :</b><p className="invitadosName">{item.NOMBRE}</p></span>

              );
            }) */}
            <span><b>Invitado :</b><p className="invitadosName">Pedro Peralta</p></span>

            {/*this.state.INVITADOS.map((item) => {
              return (

                <span><b>Válido para: </b> <p className="invitadosName">{item.NUMERO_INV}</p></span>
              );
            })*/}
            <span><b>Válido para: </b> <p className="invitadosName">2 personas</p></span>

            <div >
              <button
                onClick={onSubmit}
                className="ceremoniaLink"
              >
                Confirmacón
              </button>
            </div >


          </div>

          <div className="TextNoKds">
            <p>Adoramos a sus hijos pero deseamos que todos disfruten al máximo nuestra boda y por eso decidimos que sea solo de Adultos.</p>
          </div>

        </div>
      </div>
    );
  }
export default Horaylugar;


