import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../auth/context";
import { Link } from 'react-scroll'
// import { NombresNovios } from "../assets/svg/NombresNovios";
import { NombresNoviosNuevo } from "../assets/svg/NombresNoviosNuevo";
import { IoIosArrowDown } from 'react-icons/io'
import { Asistencia } from "./Asistencia";
import { FechaYHora } from "./FechaYHora"
import './intro.css';

export const Hero = () => {
  const refParr = useRef(null);

  const [parrIsVisible, setParrIsVisible] = useState();

  useEffect(() => {
    const { current } = refParr;
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setParrIsVisible(entry.isIntersecting);
      // console.log('entry', entry)
    })
    observer.observe(current)
    return () => {
      observer.disconnect(current); // *** Use the same element
    }
  }, [])

  const { userInfo, logout } = useContext(AuthContext);
  const { cod_empresa, nivel } = userInfo;

  const navigate = useNavigate()
  const handleDashboard = () => {
    navigate(`/dashboard/cod/${cod_empresa}`, { replace: true })
  }
  return (
    <section className='sectionInvitacionHero' id='hero' ref={refParr}>
      {(nivel === 1) &&
        <button className="btn botonADashboard" onClick={handleDashboard}>
          Lista de Invitados
        </button>
      }
      <NombresNoviosNuevo />
      <Link to='invitacionnn' smooth={true} className='linkSvg' >
        <IoIosArrowDown className='arrowSvg' />
      </Link>
      <p id="invitacionnn" className={`parragraph ${parrIsVisible ? 'show' : 'hidden'}`} >
        {/* TENEMOS EL AGRADO DE INVITARLOS A LA CELEBRACION DE */}
        Tenemos el agrado de invitarlos a la celebración de
      </p>
      <h1 className={`bodaTile ${parrIsVisible ? 'show' : 'hidden'}`} >
        Nuestra Boda
      </h1>
      <FechaYHora/>
      <Asistencia />
    </section>
  )
}
