import img000 from '../media/images/000-hotel-san-juan.jpg';
import img001 from '../media/images/001-sisprestamos.jpg';
import img002 from '../media/images/invitasoft.png';

export const cardsProjects = [
  {
    id: 101,
    img: img000,
    title: 'Hotel San Juan',
    description: 'Creamos una Web App de reserva de habitaciones con un Dashboard para la administración de las reservas y el personal.',
    tag1: 'Diseño UI/UX',
    tag2: 'Desarrollo de Software',
    url: 'http://www.hotelsanjuan.travel/'
  },
  {
    id: 102,
    img: img001,
    title: 'Sisprestamos',
    description: 'Sistema que permite llevar el control de cartera y personal al momento de desembolsar los créditos aplicado a microfinancieras',
    tag1: '',
    tag2: 'Desarrollo de Software'
  },
  {
    id: 103,
    img: img002,
    title: 'Invitasoft',
    description: 'Software de invitaciones electronias para eventos, bodas, cumpleaños, etc',
    tag1: 'Diseño UI/UX',
    tag2: 'Desarrollo de Software',
    url: '/invitaciones/landingboda-page-1'
  }
]

export const cards = [
  {
    id: 1,
    text: 'Desarrollo web',
    tooltip: '¿Dominios web? ¿Correos corporativos? ¿SEO? ¿Apps? 🧐',
    tooltip2: '¡Nombralo y lo haremos posible! 🤓',
    css: 'tooltipLeft'
  },
  {
    id: 2,
    text: 'Diseño web (UI/UX)',
    tooltip: '¿Nuevo sitio web?, ¿rediseño? o ¿Web App?',
    tooltip2: '¡Lo que necesites! Tenemos las herramientas para impulsar tu negocio 🚀',
    css: 'tooltipCenter'
  },
  {
    id: 3,
    text: 'Diseño Gráfico',
    tooltip: 'Si necesitas una marca o linea gráfica para ser reconocible y escogido entre',
    tooltip2: ' tus clientes ℹ️ ¡Nosotros te ayudamos! ✨',
    css: 'tooltipRight'
  },
]

export const movingTitle = [
  {
    id:201,
    title:'Proyectos '
  },
  {
    id:202,
    title:'Proyectos '
  },
  {
    id:203,
    title:'Proyectos '
  },
  {
    id:204,
    title:'Proyectos '
  },
  {
    id:205,
    title:'Proyectos '
  },
  {
    id:206,
    title:'Proyectos '
  },
]
