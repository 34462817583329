import React from "react";
import { Link } from 'react-router-dom';
import './NavbarNew.css';

const NavLinksNew = (props) => {
  return (
    <div className="listas">
      <div className="menuNew">
        <ul id="menuMew">
          <li onClick={() => props.esMovil && props.cerrarMenuMovil()}>
            <Link to="/invitaciones/landingboda-page-1">
              Bienvenidos</Link>
          </li>
          <li onClick={() => props.esMovil && props.cerrarMenuMovil()}>
            <Link to="/invitaciones/landingboda-page-2">
              Ceremonia y Recepción
            </Link>
          </li>
          <li onClick={() => props.esMovil && props.cerrarMenuMovil()}>
            <Link to="/invitaciones/landingboda-page-3">
              Historia
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavLinksNew;
